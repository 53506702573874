import { useEffect, useState } from 'react'
import { DayJS, errorToast } from '../../../../_metronic/helpers/Utils';
import { getRequiredCreditPayment, getStripePublishableKey, getTransaction, getTransactionInvoice } from '../../../redux/Functions/SubscriptionFuntions';
import { PageSizeCustom, PaginationCustom } from '../../../../_metronic/helpers/JobHistoryFilters';
import BootstrapTable from 'react-bootstrap-table-next';
import TableLoader from '../../../../_metronic/helpers/components/TableLoader';
import { currencySymbols, transactionStatus } from '../../../../_metronic/common/constants';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './jobAddSteps/StripeCheckoutForm';
import { changeStripePublishableKey } from '../../../redux/Slices/SubscriptionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import SuccessIcon from '../../../../_metronic/helpers/components/SuccessIcon';
import { changeJobIdForPaymentSuccess } from '../../../redux/Slices/JobSlice';
import { useNavigate } from 'react-router-dom';
import { IoMdDownload } from 'react-icons/io';
import ErrorIcon from '../../../../_metronic/helpers/components/ErrorIcon';
import { KTIcon } from '../../../../_metronic/helpers';
import { LoadStripeFunction } from '../../../redux/Functions/CommonFunctions';

export default function JobView_Transactions() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const job_id = new URLSearchParams(window.location.search).get('job_id');
    const [loading, setLoading] = useState(false);
    const [transactionData, setTransactionData] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [skip, setSkip] = useState(0);
    const [timer, setTimer] = useState(0)
    const [payLoading, setPayLoading] = useState(false)
    const [stripeCheckoutModal, setStripeCheckoutModal] = useState(false);
    const [stripeData, setStripeData] = useState<any>({})
    const loadStripePromise = LoadStripeFunction()

    const columns = [
        {
            dataField: 'no',
            text: 'No',
            formatter: (cell: any, row: any, index: number) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {index + 1 + skip}
                    </div>
                </>)
            }
        },
        {
            dataField: 'id',
            text: 'ID',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {cell || "-"}
                    </div>
                </>)
            }
        },
        {
            dataField: 'amount',
            text: 'Amount',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {cell ? <>{currencySymbols[row?.currency.toUpperCase() || "-"] || ''}{Number(cell) / 100} {row?.currency.toUpperCase() || "-"}</> : "-"}
                    </div>
                </>)
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {DayJS(cell).format('DD/MM/YYYY hh:mm A')}
                    </div>
                </>)
            }
        },
        // {
        //     dataField: 'job_credit',
        //     text: 'Credit',
        //     formatter: (cell: any, row: any) => {
        //         return (<>
        //             <div className="d-flex align-items-center">
        //                 {cell || "-"}
        //             </div>
        //         </>)
        //     }
        // },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell: any, row: any) => {
                if (cell === transactionStatus.paymentRequire) {
                    return <Button size='sm' onClick={() => handlePay(row.id)} disabled={payLoading} >Pay</Button>
                }
                if (cell === transactionStatus.pending) {
                    // return <span title='Pending'><KTIcon iconName='time' className='fs-2x text-warning' /></span>
                    return <OverlayTrigger
                        trigger="hover"
                        placement="top-start"
                        overlay={<Popover className='p-3'>
                            Pending
                        </Popover>}
                    >
                        <div className="position-relative badge badge-light-warning badge-lg">
                            <KTIcon iconName='time' className='fs-2x me-1 text-warning' /> <span>Pending</span>
                        </div>
                    </OverlayTrigger>
                }
                if (cell === transactionStatus.success) {
                    return <div className="position-relative badge badge-light-success badge-lg">
                        <SuccessIcon /> <span>Completed</span>
                    </div>
                }
                if (cell === transactionStatus.failed) {
                    return <OverlayTrigger
                        trigger="hover"
                        placement="top-start"
                        overlay={<Popover className='p-3'>
                            {row?.message}
                        </Popover>}
                    >
                        <div className="position-relative badge badge-light-danger badge-lg">
                            <ErrorIcon /> <span>{row?.message}</span>
                        </div>
                    </OverlayTrigger>
                }
                if (cell === transactionStatus.refunded) {
                    return <span title='Refunded' className='badge badge-light-primary badge-lg'><KTIcon iconName='rescue' className='fs-2x text-primary' /></span>
                }
            }
        },
        {
            dataField: 'gatewayMetadata',
            text: 'Action',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div
                        className='px-2 pt-2 pb-3 rounded-3 text-nowrap d-inline-block bg-light text-primary bg-hover-primary text-hover-white'
                        onClick={async () => {
                            const invoiceURL: any = await getTransactionInvoice({ transactionId: row?.id })
                            if (invoiceURL?.data?.length) {
                                if (!invoiceURL?.data[0]?.invoice?.hosted_invoice_url) {
                                    return errorToast("Invoice not found")
                                }
                                window.open(invoiceURL?.data[0]?.invoice?.hosted_invoice_url)
                            } else {
                                errorToast("Invoice not found")
                            }
                        }}
                    ><IoMdDownload className="fs-1" /> Invoice</div>
                </>)
            }
        },
    ]

    const handlePay = (Id: string) => {
        setPayLoading(true);
        dispatch(changeJobIdForPaymentSuccess(job_id))
        getRequiredCreditPayment(Id)
            .then((res: any) => {
                setStripeData(res?.data)
                setPayLoading(false);
                if (res?.data?.clientSecret) {
                    setStripeCheckoutModal(true);
                } else {
                    errorToast("Stripe initialization failed")
                }

            }).catch((err: any) => {
                setPayLoading(false);
                errorToast(err.message);
            })
    }

    const fetchJobTransactions = () => {
        if (!job_id) {
            errorToast("Backup ID is not found")
            navigate("/job/list")
        }
        setLoading(true);
        setTransactionData([])
        getTransaction(
            {
                jobId: job_id,
                sortOrder: "desc",
                sortBy: "createdAt",
                limit: pageSize,
                skip,
            })
            .then((res: any) => {
                setTransactionData(res?.data)
                setLoading(false);
                setTotalCount(res?.metadata?.count)
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setLoading(false);
            })
    }

    useEffect(() => {
        clearTimeout(timer);
        const newTimer: any = setTimeout(() => {
            fetchJobTransactions();
        }, 100);
        setTimer(newTimer);
        // if (!stripePublishableKey) {
        //     getStripePublishableKey()
        //         .then((res: any) => {
        //             dispatch(changeStripePublishableKey(res?.data?.publishable_key))
        //         })
        //         .catch((err: any) => {
        //             errorToast(err?.message)
        //         })
        // }
    }, [skip, pageSize])

    return (<>
        <div className="card p-5">
            <div className="filters d-flex mb-3 gap-5">
                <div>
                    <label className='fw-semibold mb-1'>Count</label>
                    <PageSizeCustom setPageSize={setPageSize} />
                </div>
            </div>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden"
                bootstrap4
                keyField='id'
                data={transactionData || []}
                columns={columns}
                noDataIndication={() => loading ? <TableLoader /> : "No Records Found"}
                rowClasses={"border-bottom"}
            />
            <div className='text-end mt-3'>
                <PaginationCustom total={totalCount} pageSize={pageSize} setSkip={setSkip} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </div>
        <Modal show={stripeCheckoutModal} backdrop="static" onHide={() => setStripeCheckoutModal(false)} centered >
            <Modal.Header closeButton> <Modal.Title>Add Subscription</Modal.Title> </Modal.Header>
            <Modal.Body >
                <Elements stripe={loadStripePromise} options={{ clientSecret: stripeData?.clientSecret, }}   >
                    <StripeCheckoutForm values={{ stripeData }} />
                </Elements>
            </Modal.Body>
        </Modal>
    </>)
}
