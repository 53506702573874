import { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { UpdateZohoWorkDrivePlatform, getPlatforms, updateAwsS3Platform, updateSftpPlatform, updateZohoPlatform } from '../../../redux/Functions/PlatformFunctions';
import { errorToast, successToast } from '../../../../_metronic/helpers/Utils';
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner';
import { Button } from 'react-bootstrap';
import { changePlatformDataById, changePlatformIsLoading, changePlatformStep } from '../../../redux/Slices/PlatformSlice';
import idx from 'idx';
import { platformsConstant } from '../../../../_metronic/common/constants';

export default function PlatformViewHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { step, isLoading } = useSelector((state: any) => state.platformData);

    const platformIdQ: any = new URLSearchParams(window.location.search).get('platform_id');
    const platformQ: any = new URLSearchParams(window.location.search).get('platform');

    const [isEditPlatformName, setIsEditPlatformName] = useState<Boolean>(false)
    const [editPlatformNameLoading, setEditPlatformNameLoading] = useState(false)
    const [data, setData] = useState<any>({});
    const [newData, setNewData] = useState<any>({})

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setNewData({ ...newData, [name]: value })
    }

    const handleEditPlatformName = () => {
        handleChangeInput({ target: { name: "platformName", value: data?.platformName } })
        setIsEditPlatformName(true);
    }

    const zohoUpdatePlatformFunc = () => {
        updateZohoPlatform({ id: platformIdQ, platformName: newData?.platformName })
            .then((res: any) => {
                successToast(res?.message);
                setData({ ...data, platformName: newData?.platformName });
                setIsEditPlatformName(false);
                setEditPlatformNameLoading(false);
            })
            .catch((err) => {
                errorToast(err?.message);
                setEditPlatformNameLoading(false)
            })
    }

    const zohoWorkDriveUpdatePlatformFunc = () => {
        UpdateZohoWorkDrivePlatform({ id: platformIdQ, platformName: newData?.platformName })
            .then((res: any) => {
                successToast(res?.message);
                setData({ ...data, platformName: newData?.platformName });
                setIsEditPlatformName(false);
                setEditPlatformNameLoading(false);
            })
            .catch((err) => {
                errorToast(err?.message);
                setEditPlatformNameLoading(false)
            })
    }

    const sftpUpdatePlatformFunc = () => {
        updateSftpPlatform({ id: platformIdQ, platformName: newData?.platformName })
            .then((res: any) => {
                successToast(res?.message);
                setData({ ...data, platformName: newData?.platformName });
                setIsEditPlatformName(false);
                setEditPlatformNameLoading(false);
            })
            .catch((err) => {
                errorToast(err?.message);
                setEditPlatformNameLoading(false)
            })
    }

    const awsS3UpdatePlatformFunc = () => {
        updateAwsS3Platform({ id: platformIdQ, platformName: newData?.platformName })
            .then((res: any) => {
                successToast(res?.message);
                setData({ ...data, platformName: newData?.platformName });
                setIsEditPlatformName(false);
                setEditPlatformNameLoading(false);
            })
            .catch((err) => {
                errorToast(err?.message);
                setEditPlatformNameLoading(false)
            })
    }

    const handlePlatformSave = async () => {
        setEditPlatformNameLoading(true);
        switch (platformQ) {
            case "zoho":
                zohoUpdatePlatformFunc();
                break;
            case "sftp":
                sftpUpdatePlatformFunc();
                break;
            case "AWS S3":
                awsS3UpdatePlatformFunc();
                break;
            case "Zoho WorkDrive":
                zohoWorkDriveUpdatePlatformFunc();
                break;
        }
    }

    useEffect(() => {
        if (!platformIdQ) { return navigate("/platform/list") }
        dispatch(changePlatformIsLoading(true))
        getPlatforms({ platform_id: platformIdQ })
            .then((data: any) => {
                setData(data?.data[0] || {});
                dispatch(changePlatformDataById(data?.data[0] || {}));
                dispatch(changePlatformIsLoading(false))
            })
            .catch((error: any) => {
                errorToast(error?.message)
                dispatch(changePlatformIsLoading(false))
            });
    }, [])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-75px symbol-fixed position-relative shadow-sm'>
                            <img
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = toAbsoluteUrl("/media/avatars/blank_profile.png");
                                }}
                                src={toAbsoluteUrl(`/media/svg/brand-logos/` + idx(platformsConstant, _ => _[data?.platform].icon))}
                                alt='Icon'
                            />
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='text-gray-800 fs-2 fw-bolder me-1'>
                                        {isLoading ?
                                            <LoaderSpinner />
                                            : isEditPlatformName ?
                                                (<>
                                                    <div className="d-flex gap-3">
                                                        <input
                                                            className="form-control"
                                                            name="platformName"
                                                            value={newData?.platformName}
                                                            onChange={handleChangeInput}
                                                        />
                                                        <Button onClick={handlePlatformSave} disabled={editPlatformNameLoading} >
                                                            {editPlatformNameLoading ? <LoaderSpinner text="please wait..." /> : "Save"}
                                                        </Button>
                                                        <Button variant="secondary" onClick={() => setIsEditPlatformName(false)} >Cancel</Button>
                                                    </div>
                                                </>)
                                                : (<>
                                                    {data?.platformName || ""}
                                                    <span onClick={handleEditPlatformName}>
                                                        <KTIcon iconName="pencil" iconType="outline" className="fs-4 ms-3 cursor-pointer text-hover-primary text-decoration-underline " />
                                                    </span>
                                                </>)
                                        }
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                    <div className='d-flex align-items-center text-gray-400 mb-2'>
                                        {data?.platform || ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <div
                                className={
                                    `nav-link cursor-pointer text-active-primary me-6 ` +
                                    (step === 'overview' && 'active')
                                }
                                onClick={() => dispatch(changePlatformStep("overview"))}
                            >
                                Overview
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
