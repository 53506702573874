import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from '../Functions/CommonFunctions';

const initialState = {
    isLoading: false,
    stripePublishableKey: "",
    subscriptionPlans: []
}

export const transactionSlice = createSlice({
    name: 'subscriptionData',
    initialState,
    reducers: {
        changeStripePublishableKey: (state, { payload }) => {
            state.stripePublishableKey = payload
        },
        changeSubscriptionPlans: (state, { payload }) => {
            state.subscriptionPlans = payload
        },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const { changeStripePublishableKey, changeSubscriptionPlans } = transactionSlice.actions

export default transactionSlice.reducer