import { useEffect, useState } from 'react'
import { CronJobCounter, DayJS } from '../../../../../_metronic/helpers/Utils'
import { biWeekDaysArray, getLabeledOptions } from '../../../../../_metronic/common/constants'
import Cron from 'react-js-cron'
import { Cron as Croner } from "croner";
import ReactSelect from 'react-select'
import { changeCronCount } from '../../../../redux/Slices/PlatformSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { KTIcon } from '../../../../../_metronic/helpers';
import { Form } from 'react-bootstrap';

export default function JobAddStep4({ values, setValues, errors }: any) {
    const dispatch = useDispatch<AppDispatch>()
    const [timeLabeledOptions, setTimeLabeledOptions] = useState([])
    // const [cronValue, setCronValue] = useState(values.cronString);
    const [nextRunsDates, setNextRunsDates] = useState<any>([])
    const [credits, setCredits] = useState(4)
    const [creditPay, setCreditPay] = useState(5 + ((credits - 1) * 2))

    const handleChangeCredit = ({ target }: any) => {
        setCredits(target.value)
        if (!target.value || isNaN(Number(target.value))) { return setCreditPay(0) }

        setCreditPay(5 + ((Number(target.value) - 1) * 2));

    }

    const handleSelectChange = (e: any) => {
        setValues({ ...values, timeZone: e })
    }

    const validateCronWith24HourGap = (cronString: string) => {
        try {
            let startDate = DayJS().tz(values?.timeZone?.value || undefined).toDate();
            let endDate = DayJS().add(30, "days").tz(values?.timeZone?.value || undefined).toDate()
            const nextRuns = Croner(cronString, { timezone: values?.timeZone?.value || undefined, startAt: startDate, stopAt: endDate }).nextRuns(31);
            if (nextRuns.length &&
                nextRuns.some((nextRun: any, ind: number) => {
                    if (ind < nextRuns.length - 1) {
                        const currentDateTime = DayJS(nextRun);
                        const nextDateTime = DayJS(nextRuns[ind + 1]);
                        const hoursDifference = nextDateTime.diff(currentDateTime, 'hours');
                        return hoursDifference < 24;
                    }
                })
            ) { return false } else { return true }
        } catch (error) { console.error('Error parsing cron expression:', error) }
    };

    const handleRadioChange = ({ target }: any) => {
        if (target.value === 'bi-weekly') {
            setValues({
                ...values,
                cronType: target.value,
                biWeeklyDaySelected: biWeekDaysArray[0].label,
                cronString: `0 0 21 * * ${biWeekDaysArray[0].value}#1,${biWeekDaysArray[0].value}#3`
            });
        } else {
            setValues({
                ...values,
                cronType: target.value,
                cronString: "0 2 * * *"
            });
        }
    }

    const handleWeekDayClick = (item: { label: string, value: number }) => {
        if (values.biWeeklyDaySelected !== item.label) {
            setValues({
                ...values,
                biWeeklyDaySelected: item.label,
                cronString: `0 0 21 * * ${item.value}#1,${item.value}#3`
            })
        }
    }

    const handleAlternateWeek = ({ target }: any) => {
        const [first, second] = target.value.split(",")
        let findDay: any = biWeekDaysArray.find((day: any) => day.label === values.biWeeklyDaySelected)
        setValues({
            ...values,
            alternate: [first, second],
            cronString: `0 0 21 * * ${findDay.value}#${first},${findDay.value}#${second}`
        })
    }

    useEffect(() => {
        const getTimeZones = async () => {
            const timeZoneOptions = getLabeledOptions()
            setTimeLabeledOptions(timeZoneOptions)
        }
        getTimeZones();
    }, [])

    useEffect(() => {
        if (values.cronString) {
            let startDate = DayJS().tz(values?.timeZone?.value || undefined).toDate();
            let endDate = DayJS().add(1, "months").tz(values?.timeZone?.value || undefined).toDate();
            // const count = CronJobCounter({ cronString: values.cronString, endDate: DayJS().add(30, "days").toDate() });
            const count = CronJobCounter({ cronString: values.cronString, startDate, endDate } as any);
            const nextRuns = Croner(values.cronString, { timezone: values?.timeZone?.value || undefined, interval: 86400, stopAt: endDate }).nextRuns(35);
            setValues({ ...values, cronString: values.cronString, maxRuns: nextRuns.length });
            dispatch(changeCronCount(nextRuns.length))
            setNextRunsDates(nextRuns)
        }
    }, [values.cronString, values.timeZone])

    return (
        <>
            {/* <div className="w-100 border border-danger border-dashed rounded-4 bg-light-warning p-5 mb-5">
                <h4 className="text-danger mb-5 d-flex align-items-center">
                    <KTIcon iconName='information-5' className='fs-2tx text-danger me-2' />
                    Important Notice Regarding Schedule Modifications and Credit Usage
                </h4>

                <p>We want to bring to your attention a crucial aspect of our service concerning schedule modifications and credit usage.</p>
                <p>In the event that you decide to modify your schedule in the future, it's important to note that you may require additional credits if your current allocation proves insufficient for the updated schedule. Should such a situation arise, you will need to purchase additional credits to ensure the smooth continuation of your tasks.</p>

                <p><strong>Scenario 1: Upgrade of Plan with Insufficient Credits</strong></p>
                <p>If your current subscription allows for 10 Backups per month at a rate of $15.99 per month, and you opt to adjust your schedule to accommodate 17 Backups per month, which corresponds to a different subscription priced at $24.99 per month, there might be a transitional phase where your current credits are insufficient. In this scenario, let's assume you require 4 additional credits to cover your needs for the current month.</p>
                <p>Here's how the calculation would work:</p>
                <div>- The minimum amount charged for 1 credit is $5.</div>
                <p>- For each additional credit beyond the initial one, there is an extra charge of $2 per credit.</p>
                <p>Therefore, to purchase <input type="number" className="py-0 w-30px d-inline text-center" style={{ minHeight: "unset" }} value={credits} onChange={handleChangeCredit} /> credits, the total cost would be calculated as follows:</p>

                <div>Minimum Amount + Additional Credits</div>
                <div>= $5 + ({credits || 0} - 1) * $2</div>
                <p>= ${creditPay}</p>
                <p>Hence, you would need to pay ${creditPay} to acquire the necessary credits to fulfill your current requirements.</p>
                <p>Please be assured that to streamline this process and provide you with uninterrupted service, we will automatically update your subscription from the following month to align with your modified schedule.</p>
                <p>Should you have any queries or require further clarification regarding this matter, please don't hesitate to reach out to us. We're here to assist you every step of the way.</p>

                <p><strong>Scenario 2: Downgrade of Plan with Insufficient Credits</strong></p>
                <p>If your modified schedule entails a downgrade from your existing plan and you require additional credits to meet your needs, you will need to purchase the necessary credits. Your plan will be downgraded from the next billing cycle onwards.</p>

                <p><strong>Scenario 3: Downgrade of Plan with Sufficient Credits</strong></p>
                <div>
                    Should your modified schedule involve a downgrade from your existing plan and you possess sufficient credits to fulfill upcoming tasks, we will adjust your plan accordingly without any additional payment. However, it's important to note that our subscriptions and transactions are non-refundable unless there is a serious issue. Therefore, in the case of downgrades or upgrades, we do not refund remaining credits.
                </div>
            </div> */}
            <div className='w-100'>
                <div className="border border-secondary p-5 mb-5 rounded">
                    <div className="d-flex gap-5 mb-5 align-items-center">
                        <div className='d-flex gap-2 fs-5 align-items-center'>
                            <Form.Check
                                size={5}
                                type="radio"
                                className='form'
                                id='custom'
                                name='custom'
                                value="custom"
                                checked={values.cronType === 'custom'}
                                onChange={handleRadioChange}
                            />
                            <label role='button' htmlFor="custom">Custom</label>
                        </div>
                        <div className='d-flex gap-2 fs-5 align-items-center'>
                            <Form.Check
                                type="radio"
                                className='form'
                                id='bi-weekly'
                                name='bi-weekly'
                                value="bi-weekly"
                                checked={values.cronType === 'bi-weekly'}
                                onChange={handleRadioChange}
                            />
                            <label role='button' htmlFor="bi-weekly">Twice a month</label>
                        </div>
                    </div>

                    {values.cronType === 'custom' && (<>
                        <Cron
                            value={values.cronString}
                            setValue={(cronString: string) => {
                                if (validateCronWith24HourGap(cronString)) {
                                    return setValues({ ...values, cronString, cronStringNotAllowed: false });
                                }
                                setValues({ ...values, cronString, cronStringNotAllowed: true });
                            }}
                            defaultPeriod='week'
                            allowedPeriods={["month", "week"]}
                            allowedDropdowns={["month-days", "months", "period", "week-days", "hours", "minutes"]}
                        />
                        {errors?.cronStringNotAllowed &&
                            <div className="alert alert-danger" role="alert">
                                {errors.cronStringNotAllowed}
                            </div>}
                    </>)}

                    {values.cronType === "bi-weekly" && <><div className='d-flex gap-3 flex-wrap mb-3'>
                        {biWeekDaysArray.map((item, index) => {
                            return (<>
                                <div
                                    className={`border border-2 py-1 px-4 rounded-2 cursor-pointer ${values.biWeeklyDaySelected === item.label ? "border-primary shadow-inner fw-bold" : "border-secondary"}`}
                                    key={index}
                                    onClick={() => handleWeekDayClick(item)}
                                >
                                    {item.label}
                                </div>
                            </>)
                        })}
                    </div>
                        <div className='d-flex align-items-center gap-4 mb-3'>
                            <label htmlFor="">Choose alternate week:</label>
                            <Form.Select id='listAlternateWeek' className="w-auto form-select-sm" value={values?.alternate?.join()} onChange={handleAlternateWeek}>
                                <option value="1,3">1st and 3rd</option>
                                <option value="2,4">2st and 4rd</option>
                            </Form.Select>
                        </div>
                        {values.biWeeklyDaySelected && <div className=' text-muted'>* Backup will be run on every alternate <span className='fw-semibold text-black'>{values.biWeeklyDaySelected}</span></div>}
                    </>}
                </div>

                <ReactSelect
                    options={timeLabeledOptions}
                    isClearable={true}
                    placeholder="Select Time Zone"
                    onChange={handleSelectChange}
                    value={values.timeZone}
                />
                {errors?.timeZone &&
                    <div className="alert alert-danger mt-3" role="alert">
                        {errors?.timeZone?.value}
                    </div>
                }

                <table className='table table-bordered table-striped table-secondary mt-5' >
                    <thead className='table-warning text-center '>
                        <tr>
                            <th scope='col'>Estimated schedule Backups per 30 days ({nextRunsDates.length || 0} runs maximum)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nextRunsDates?.map((nextRun: any, index: number) => {
                            return (<>
                                <tr key={"nextRuns" + index}><td>{DayJS(nextRun).tz(values?.timeZone?.value || undefined).format("dddd DD MMM YYYY hh:mm:ss A")}</td></tr>
                            </>)
                        })}
                    </tbody>
                </table>
                {errors?.maxRuns &&
                    <div className="alert alert-danger mt-3" role="alert">
                        {errors?.maxRuns}
                    </div>
                }
            </div>
        </>
    )
}
