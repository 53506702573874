import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../AssetHelpers"

export default function TableLoader({ notInTable }: { notInTable?: boolean }) {
    return (
        <>
            <SVG src={toAbsoluteUrl("/media/svg/general/Infinity-loader.svg")} height={100} style={{ margin: !notInTable ? "calc(100vh - 595px)" : "0" }} />
        </>
    )
}
