import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getZohoAccountInfo, zohoHealthCheck } from '../../../../redux/Functions/PlatformFunctions';
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner';
import { errorToast, successToast } from '../../../../../_metronic/helpers/Utils';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useSelector } from 'react-redux';

export default function ZohoPlatformOverviewAccountInfo() {
    const { health } = useSelector((state: any) => state.platformData.platformsDataById)
    const platformId = new URLSearchParams(window.location.search).get('platform_id');
    const [loading, setLoading] = useState<boolean>(false);
    const [healthCheckLoading, setHealthCheckLoading] = useState(false);
    const [healthStatus, setHealthStatus] = useState<"not checked" | "healthy" | "unhealthy">("not checked")
    const [data, setData] = useState<any>({});
    const getAccountInformation = async () => {
        setLoading(true)
        getZohoAccountInfo(platformId)
            .then((res: any) => {
                setData(res.data?.org[0] || {});
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false)
            });
    }

    const handleHealthCheck = async () => {
        setHealthCheckLoading(true);
        setHealthStatus('not checked');
        zohoHealthCheck(platformId)
            .then((res: any) => {
                successToast(res?.message)
                setHealthStatus('healthy');
                setHealthCheckLoading(false);
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setHealthStatus('unhealthy');
                setHealthCheckLoading(false);
            })
    }

    useEffect(() => {
        if (!platformId) { return; }
        getAccountInformation()
    }, [])

    return (<>
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer align-items-center'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Account Information</h3>
                    </div>

                    <Button size='sm' className={`${healthStatus === "healthy" ? 'btn-success' : healthStatus === "unhealthy" ? 'btn-danger' : 'btn-info'}`} onClick={handleHealthCheck} disabled={healthCheckLoading} >
                        {healthCheckLoading ?
                            <LoaderSpinner text='please wait...' /> :
                            <>{healthStatus === "healthy" ?
                                <KTIcon iconName='check' className='fs-1' /> :
                                healthStatus === "unhealthy" ?
                                    <KTIcon iconName='cross' iconType='solid' className='fs-1' /> :
                                    ""}Re-check Health</>}
                    </Button>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>ID</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{loading ? <LoaderSpinner /> : data?.id}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Account Name</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{loading ? <LoaderSpinner /> : data?.company_name}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Email</label>
                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{loading ? <LoaderSpinner /> : data?.primary_email}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-lg-4 fw-bold text-muted'>Authorization Health</label>
                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{loading ? <LoaderSpinner /> : health}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </>)
}
