import React from 'react'
import { KTIcon } from './KTIcon'

interface ISuccess {
    color?: string
}
export default function SuccessIcon({ color = "success" }: ISuccess) {
    return (
        <><KTIcon iconName='check-circle' iconType='duotone' className={`fs-2x text-${color || "success"} `} /></>
    )
}
