import React from 'react'
import { KTIcon } from './KTIcon'
interface IErrorIcon {
    onClick?: () => void;
}

export default function ErrorIcon({ onClick }: IErrorIcon) {
    return (
        <span onClick={onClick}><KTIcon iconName='cross-circle' iconType='duotone' className='fs-2x text-danger' /></span>
    )
}
