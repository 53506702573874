interface loaderSpinnerInterface {
    text?: string;
    color?: string;
    md?: boolean;
}

export default function LoaderSpinner({ text, color, md }: loaderSpinnerInterface) {
    return (
        <div className={`d-inline-flex align-items-center ${color ? ("text-" + color) : ""}`}>
            <span className={`spinner-border ${md ? "" : "spinner-border-sm"}`} />
            {text ? <span className='ms-2 text-nowrap'>{text}</span> : null}
        </div>
    )
}
