import { useState } from 'react';
import { Button } from 'react-bootstrap';
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useSelector } from 'react-redux';
import SftpServerAddModal from './SftpServerAddModal';

export default function SftpPlatformOverviewAccountInfo() {
    const { credentials, platformName, id, health } = useSelector((state: any) => state.platformData.platformsDataById)
    const { isLoading } = useSelector((state: any) => state.platformData);
    const [sftpAddModal, setSftpAddModal] = useState(false);
    const [sftpModalData, setSftpModalData] = useState<any>({})
    const [passwordFlags, setPasswordFlags] = useState<any>({
        isPasswordShow: false,
        isPassphraseShow: false
    })

    const changePasswordFlag = (flag: string) => setPasswordFlags({ ...passwordFlags, [flag]: !passwordFlags?.[flag] })

    const handleEditConfiguration = () => {
        setSftpModalData({
            id,
            host: credentials?.host,
            port: credentials?.port,
            path: credentials?.path,
            username: credentials?.username,
            password: credentials?.password,
            platformName: platformName,
            passphrase: credentials?.passphrase,
            privateKeyFile: credentials?.privateKeyFile
        })
        setSftpAddModal(true)
    }


    return (<>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer align-items-center'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Server Configuration</h3>
                </div>

                <Button onClick={handleEditConfiguration} disabled={isLoading} >
                    Edit Configuration
                </Button>
            </div>

            <div className='card-body px-9 py-5'>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Host</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{isLoading ? <LoaderSpinner /> : credentials?.host || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Port</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{isLoading ? <LoaderSpinner /> : credentials?.port || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Path</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.path || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Username</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.username || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Password</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                            {isLoading ? <LoaderSpinner /> : passwordFlags?.isPasswordShow ? credentials?.password : credentials?.password ? "********" : "-"}
                            {credentials?.password && <span role='button' onClick={() => changePasswordFlag("isPasswordShow")} ><KTIcon iconName={passwordFlags?.isPasswordShow ? "eye-slash" : "eye"} iconType='solid' className='ms-1 fs-4 text-dark' /></span>}
                        </span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Private Key</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.privateKeyFile ? <a href={credentials?.privateKeyFile}><KTIcon iconName='file-down' className='fs-2x mt-1 text-success' /></a> : "-"}</span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-3 fw-bold text-muted'>Passphrase</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                            {isLoading ? <LoaderSpinner /> : passwordFlags.isPassphraseShow ? credentials?.passphrase : credentials?.passphrase ? "********" : "-"}
                            {credentials?.passphrase && <span role='button' onClick={() => changePasswordFlag("isPassphraseShow")} ><KTIcon iconName={passwordFlags?.isPassphraseShow ? "eye-slash" : "eye"} iconType='solid' className='ms-1 fs-4 text-dark' /></span>}
                        </span>
                    </div>
                </div>
                <div className='row'>
                    <label className='col-lg-3 fw-bold text-muted'>Authorization Health</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : health}</span>
                    </div>
                </div>
            </div>
        </div>
        <SftpServerAddModal sftpAddModal={sftpAddModal} setSftpAddModal={setSftpAddModal} initData={sftpModalData} isEdit={true} />
    </>)
}
