import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { IAWSAdd, awsAddSchema } from '../../../../../_metronic/common/constants'
import { ApiPatch, ApiPost, errorToast, successToast } from '../../../../../_metronic/helpers/Utils'
import { useNavigate } from 'react-router-dom'
import { getPlatforms } from '../../../../redux/Functions/PlatformFunctions'
import { changePlatformDataById } from '../../../../redux/Slices/PlatformSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner'

export default function AwsServerAddModal({ awsAddModal, setAwsAddModal, initData, isEdit }: any) {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const formik = useFormik<IAWSAdd>({
        initialValues: {
            platformName: "",
            access_key_id: "",
            secret_key_id: "",
            region: "",
            bucket_name: "",
            path: "",
            endpoint: ""
        },
        validationSchema: awsAddSchema,
        onSubmit: (values) => {
            setLoading(true)
            const body: any = {
                platformName: values.platformName,
                access_key_id: values.access_key_id,
                secret_key_id: values.secret_key_id,
                region: values.region,
                bucket_name: values.bucket_name,
                path: values.path,
            }
            if (values.endpoint) { body.endpoint = values.endpoint }
            if (isEdit) {
                ApiPatch("/platform/aws-s3/update/" + initData.id, body)
                    .then((res: any) => {
                        successToast(res?.message);
                        getPlatforms({ platform_id: initData.id })
                            .then((data: any) => {
                                setLoading(false);
                                dispatch(changePlatformDataById(data?.data[0] || {}));
                                setAwsAddModal(false);
                            })
                            .catch((error: any) => {
                                errorToast(error?.message);
                                setAwsAddModal(false);
                            });
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    })
            } else {
                ApiPost("/platform/aws-s3/add", body)
                    .then((res: any) => {
                        successToast(res?.message);
                        navigate("/platform/list")
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    })

            }
        },
    })

    useEffect(() => {
        formik.setValues({ ...formik.values, ...initData })
    }, [initData])

    return (
        <>
            <Modal show={awsAddModal} onHide={setAwsAddModal} backdrop="static" centered >
                <Modal.Header className='p-5' closeButton>
                    <Modal.Title>AWS S3 Server {isEdit ? "Update" : "Add"}</Modal.Title>
                </Modal.Header>
                <form autoComplete='off' onSubmit={formik.handleSubmit} noValidate className='form'>
                    <Modal.Body>
                        <div>
                            <label className='fw-bold fs-6 mb-1'>Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Give a name to your platform'
                                {...formik.getFieldProps('platformName')}
                            />
                            {formik.touched.platformName && formik.errors.platformName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.platformName}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Access Key ID</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Access key ID'
                                {...formik.getFieldProps('access_key_id')}
                            />
                            {formik.touched.access_key_id && formik.errors.access_key_id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.access_key_id}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Secret Key ID</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Secret key ID'
                                {...formik.getFieldProps('secret_key_id')}
                            />
                            {formik.touched.secret_key_id && formik.errors.secret_key_id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.secret_key_id}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Region</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Region'
                                {...formik.getFieldProps('region')}
                            />
                            {formik.touched.region && formik.errors.region && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.region}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Bucket Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Bucket Name'
                                {...formik.getFieldProps('bucket_name')}
                            />
                            {formik.touched.bucket_name && formik.errors.bucket_name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.bucket_name}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Path</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Path'
                                {...formik.getFieldProps('path')}
                            />
                            {formik.touched.path && formik.errors.path && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.path}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Endpoint</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Endpoint'
                                {...formik.getFieldProps('endpoint')}
                            />
                            {formik.touched.endpoint && formik.errors.endpoint && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.endpoint}</div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-3'>
                        <Button type='submit' className={`loaderBtn position-relative overflow-hidden ${loading ? "progress1" : ""}`} disabled={loading}>
                            {loading ? (
                                <LoaderSpinner text='Please wait...' />
                            ) : (
                                isEdit ? "Update" : <><KTIcon iconName='plus' className='fs-2' />Add</>
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
