import { useEffect, useState } from 'react'
import { cancelSubscription, createSubscription, getStripePublishableKey, getSubscription, pauseResumeSubscription } from '../../../redux/Functions/SubscriptionFuntions';
import { errorToast } from '../../../../_metronic/helpers/Utils';
import TableLoader from '../../../../_metronic/helpers/components/TableLoader';
import { useDispatch, useSelector } from 'react-redux';
import { changeStripePublishableKey } from '../../../redux/Slices/SubscriptionSlice';
import { AppDispatch } from '../../../redux/store';
import { Button, Modal } from 'react-bootstrap';
import StripeCheckoutForm from './jobAddSteps/StripeCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner';
import { changeJobIdForPaymentSuccess } from '../../../redux/Slices/JobSlice';
import { useNavigate } from 'react-router-dom';
import { stripePublishableKey, subscriptionStatus } from '../../../../_metronic/common/constants';
import Swal from 'sweetalert2';
import { LoadStripeFunction } from '../../../redux/Functions/CommonFunctions';

export default function JobView_Subscription() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const job_id = new URLSearchParams(window.location.search).get('job_id');
    const [loading, setLoading] = useState(false);
    const [payLoading, setPayLoading] = useState<number | null>(null)
    const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false)
    const [pauseResumeSubscriptionLoading, setPauseResumeSubscriptionLoading] = useState(false)
    const [subscriptionData, setSubscriptionData] = useState<any>([]);
    const [transactionData, setTransactionData] = useState<any>({})
    // const { subscriptionPlans } = useSelector((state: any) => state.subscriptionData);
    const [stripeCheckoutModal, setStripeCheckoutModal] = useState(false);
    const loadStripePromise = LoadStripeFunction()

    const handlePay = (stripeProductId: any, index: number) => {
        setPayLoading(index);
        createSubscription({ stripeProductId, jobId: job_id })
            .then((res: any) => {
                setTransactionData(res?.data);
                dispatch(changeJobIdForPaymentSuccess(job_id))
                if (stripePublishableKey) {
                    setStripeCheckoutModal(true)
                }
                setPayLoading(null);
            })
            .catch((err: any) => {
                errorToast(err.message)
                setPayLoading(null);
            });
    }

    const handleCancelSubscription = async (Id: string) => {
        let isConfirm = false;
        await Swal.fire({
            icon: "warning",
            iconColor: "#f14141",
            title: "If you cancel your subscription,\n All upcoming backups will be paused immediately.\n No refund will be initiated for any remaining backup credits.",
            showCancelButton: true,
            confirmButtonColor: "#f14141",
            confirmButtonText: "Cancel",
            cancelButtonColor: "transparent",
            cancelButtonText: "No, Cancel",
            customClass: {
                title: "fs-5"
            },
            preConfirm: () => {
                isConfirm = true;
            }
        })
        if (!isConfirm) { return }
        setCancelSubscriptionLoading(true);
        await cancelSubscription(Id)
            .then((res: any) => {
                fetchJobSubscriptions()
                setCancelSubscriptionLoading(false);
            })
            .catch((err: any) => {
                errorToast(err.message)
                setCancelSubscriptionLoading(false);
            });
    }

    const handlePauseResumeSubscription = async (Id: string, status: boolean) => {
        if (status) {
            let isConfirm = false;
            await Swal.fire({
                icon: "warning",
                title: "If you pause your subscription,\n All upcoming Backups will be paused immediately",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Pause",
                cancelButtonColor: "transparent",
                cancelButtonText: "No, Cancel",
                customClass: {
                    title: "fs-5"
                },
                preConfirm: () => {
                    isConfirm = true;
                }
            })
            if (!isConfirm) { return }
        }
        setPauseResumeSubscriptionLoading(true);
        await pauseResumeSubscription(Id, { isPause: status })
            .then((res: any) => {
                fetchJobSubscriptions()
                setPauseResumeSubscriptionLoading(false);
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setPauseResumeSubscriptionLoading(false);
            });
    }

    const fetchJobSubscriptions = () => {
        setLoading(true);
        setSubscriptionData([])
        if (!job_id) {
            errorToast("Backup ID is not found")
            navigate("/job/list")
        }
        getSubscription(
            {
                jobId: job_id,
                sortOrder: "desc",
                sortBy: "createdAt",
                // limit: pageSize,
                // skip,
            })
            .then((res: any) => {
                setSubscriptionData(res?.data)
                setLoading(false);
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setLoading(false);
            })
    }
    useEffect(() => {
        fetchJobSubscriptions();
        // if (!Object.keys(subscriptionPlans).length) {
        //     getSubscriptionPlans()
        //         .then((res: any) => {
        //             dispatch(changeSubscriptionPlans(res?.data))
        //         })
        //         .catch((err: any) => {
        //             errorToast(err?.message)
        //         })
        // }
        if (!stripePublishableKey) {
            // getStripePublishableKey()
            //     .then((res: any) => {
            //         dispatch(changeStripePublishableKey(res?.data?.publishable_key))
            //     })
            //     .catch((err: any) => {
            //         errorToast(err?.message)
            //     })
        }
    }, [stripePublishableKey])
    let authLocal: any = localStorage.getItem('backupSuitAuth')
    var localAuthData = JSON.parse(authLocal);
    return (<>
        <div>
            {subscriptionData?.length ?
                subscriptionData?.map((v: any, index: number) => {
                    return (
                        <div className='border border-secondary bg-white rounded-4 py-5 px-8 mt-5' key={"aa" + index} >
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h3 className='text-uppercase'>{v?.productCurrency === "usd" && "$"}{(v?.productPrice / 100) || "-"} {v?.productCurrency}</h3>
                                    <h5 className='text-info m-0' >{v?.interval === "month" && "Monthly"}</h5>
                                </div>
                                <div>
                                    <div className='d-flex gap-5 align-items-center justify-content-end'>
                                        {(v?.status === "incomplete") && <div>
                                            <Button
                                                size='sm'
                                                onClick={() => handlePay(v?.productId, index)}
                                                disabled={payLoading !== null}
                                            >
                                                {payLoading === index ? <LoaderSpinner /> : "Pay"}
                                            </Button>
                                        </div>}
                                        <h5 className={`text-capitalize text-${v?.status === subscriptionStatus.active ?
                                            "success" :
                                            v?.status === subscriptionStatus.incomplete ?
                                                "warning" :
                                                v?.status === subscriptionStatus.cancelled ?
                                                    "danger" :
                                                    v?.status === subscriptionStatus.paused ?
                                                        "primary" :
                                                        ""} `} >{v?.status}</h5>
                                    </div>
                                    <div className="d-flex justify-content-end gap-2">
                                        {(v?.status === subscriptionStatus.active || v?.status === subscriptionStatus.paused) && (
                                            <button className={`btn btn-${v?.status === subscriptionStatus.active ? "primary" : "success"} fs-8 py-1 px-3`} onClick={() => handlePauseResumeSubscription(v?.id, v?.status === subscriptionStatus.active)} disabled={pauseResumeSubscriptionLoading || cancelSubscriptionLoading} >
                                                {pauseResumeSubscriptionLoading ?
                                                    <LoaderSpinner text='please wait...' /> : (
                                                        v?.status === subscriptionStatus.active ?
                                                            "Pause" :
                                                            v?.status === subscriptionStatus.paused ?
                                                                "Resume" :
                                                                "")}
                                            </button>
                                        )}
                                        {v?.status !== "cancelled" && (
                                            <button className="btn btn-danger fs-8 py-1 px-3" onClick={() => handleCancelSubscription(v?.id)} disabled={cancelSubscriptionLoading || pauseResumeSubscriptionLoading || payLoading !== null} >
                                                {cancelSubscriptionLoading ?
                                                    <LoaderSpinner text='please wait...' /> :
                                                    "Cancel"}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                <><div className='d-flex justify-content-center align-items-center' style={{ minHeight: "300px" }} >{loading ? <TableLoader notInTable /> : "No Records Found"}</div></>
            }
        </div>
        {/* <div className="w-300px">
            <div className="card card-custom card-stretch shadow mb-5">
                <div className="card-body d-flex flex-row border">
                    <div>
                        <img className="h-30px w-50px" src="https://t3.ftcdn.net/jpg/05/60/50/16/360_F_560501607_x7crxqBWbmbgK2k8zOL0gICbIbK9hP6y.jpg" />
                    </div>
                    <div className="px-3 ">
                        <p className="text-gray-800 fs-5 fw-bolder me-1">{localAuthData.firstName + " " + localAuthData.lastName}</p>
                        <p className="text-gray-700 fs-6 me-1">Card ending with ****</p>
                        <p className="text-gray-700 fs-6 me-1">Next Invoice: 2024/06/01</p>
                    </div>
                    <div className="ms-auto">
                        <h5 className="text-capitalize text-danger justify-content-end">{subscriptionData?.map((v: any) => v?.status)}</h5>
                    </div>
                </div>
                <div className="m-5 d-flex justify-content-end gap-2">
                    <button className="btn btn-info fs-8 py-2 px-3">Change payment method</button>
                    <button className="btn btn-primary fs-8 py-2 px-3">Pause</button>
                    <button className="btn btn-danger fs-8 py-2 px-3">Cancel</button>
                </div>
            </div>
        </div> */}
        <Modal show={stripeCheckoutModal} backdrop="static" onHide={() => setStripeCheckoutModal(false)} centered >
            <Modal.Header closeButton> <Modal.Title>Add Subscription</Modal.Title> </Modal.Header>
            <Modal.Body >
                <Elements stripe={loadStripePromise} options={{ clientSecret: transactionData?.clientSecret, }}   >
                    <StripeCheckoutForm values={{ transactionData }} />
                </Elements>
            </Modal.Body>
        </Modal>
    </>)
}
