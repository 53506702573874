import React, { useEffect, useState } from 'react'
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner'
import { getZohoBackupURLs } from '../../../../redux/Functions/PlatformFunctions';
import { successToast } from '../../../../../_metronic/helpers/Utils';

export default function ZohoPlatformOverviewBackupURLs() {
    const platformId = new URLSearchParams(window.location.search).get('platform_id');
    const [loading, setLoading] = useState(false);
    const [backupUrls, setBackupUrls] = useState<any>([]);
    const [attachmentUrls, setAttachmentUrls] = useState<any>([])

    const getBackupURLS = async () => {
        setLoading(true)
        getZohoBackupURLs(platformId)
            .then((res: any) => {
                setBackupUrls(res?.data?.urls?.data_links || []);
                setAttachmentUrls(res?.data?.urls?.attachment_links || []);
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false)
            });
    }

    useEffect(() => {
        if (!platformId) { return; }
        getBackupURLS()
    }, [])

    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer align-items-center'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Backup URLs</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <ul>

                        {backupUrls?.map((item: any) => {
                            return <li
                                className='text-hover-primary cursor-pointer'
                                onClick={(e: any) => { navigator.clipboard.writeText(e.target.innerText).then(() => successToast("Text Copied")) }}
                            >{item}</li>
                        })}
                    </ul>
                </div>
            </div>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer align-items-center'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Attachment URLs</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <ul>

                        {attachmentUrls?.map((item: any) => {
                            return <li
                                className='text-hover-primary cursor-pointer'
                                onClick={(e: any) => { navigator.clipboard.writeText(e.target.innerText).then(() => successToast("Text Copied")) }}
                            >{item}</li>
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}
