import axios from "axios"
import { AuthModel } from "./_models"
import { AUTH_LOCAL_STORAGE_KEY } from "./AuthHelpers"

export const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/user/account/login`
export const REGISTER_URL = `${API_URL}/user/account/create`
export const FORGOT_PASSWORD_URL = `${API_URL}/user/account/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/user/account/forgot-password/verify`
const localStorageData: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
const parsedLocalStorageData: any = JSON.parse(localStorageData)
export const configToken = {
  headers: { Authorization: "Bearer " + parsedLocalStorageData?.authorization },
}
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  profileImage: string,
  signup_code?: string
) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    email,
    password,
    profileImage,
    signup_code,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function forgotPassword(email: string) {
  return axios.post<{ result: boolean }>(FORGOT_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(email: string, otp: string, newPassword: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    otp,
    newPassword,
  })
}

export function resendOTP(email: string, otp: string, newPassword: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    otp,
    newPassword,
  })
}
