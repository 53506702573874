import cronstrue from "cronstrue"
import { useSelector } from "react-redux"
import SuccessIcon from "../../../../../_metronic/helpers/components/SuccessIcon";
import { useEffect, useState } from "react";
import { getSubscription, getSuitableProduct } from "../../../../redux/Functions/SubscriptionFuntions";
import { errorToast } from "../../../../../_metronic/helpers/Utils";
import LoaderSpinner from "../../../../../_metronic/helpers/components/LoaderSpinner";
import { getPaymentMethods } from "../../../../redux/Functions/BillingFunctions";

export default function JobAddStep5Preview({ values, setValues, stepIndex, setLoading: setSubmitLoading }: any) {
    const { cronCount } = useSelector((state: any) => state.platformData);
    const [loading, setLoading] = useState(false)
    const [suitableProjectData, setSuitableProjectData] = useState<any>({})

    const findJobIdNullSubscription = async () => {
        return await getSubscription()
            .then((result: any) => {
                const findNull = result?.data?.find((v: any) => !v?.jobId)
                if (findNull) {
                    setSuitableProjectData({
                        price: {
                            unit_amount: findNull?.productPrice,
                            currency: findNull?.productCurrency
                        },
                    })
                    setValues({ ...values, subscriptionId: findNull?.id })
                    setLoading(false)
                    setSubmitLoading(false);
                    return true;
                } else { return false; }
            })
            .catch((error: any) => {
                errorToast(error?.message)
                return false;
            })
    }

    useEffect(() => {
        const effectFunction = async () => {
            if (stepIndex === 5) {
                setLoading(true)
                setSubmitLoading(true)
                const findNull: any = await findJobIdNullSubscription()
                if (!findNull) {
                    getSuitableProduct({
                        schedule: values.cronString,
                        timeZone: values.timeZone.value
                    })
                        .then(async (res: any) => {
                            setSuitableProjectData(res?.data?.product)
                            await getPaymentMethods()
                                .then((result: any) =>
                                    setValues({
                                        ...values,
                                        stripeProductId: res?.data?.product?.id,
                                        stripePriceId: res?.data?.product?.price?.id,
                                        paymentMethodId: result?.data[0]?.stripe_payment_id,
                                    }))
                                .catch((err: any) => errorToast(err?.message))
                            setLoading(false)
                            setSubmitLoading(false);
                        })
                        .catch((err) => {
                            setLoading(false);
                            setSubmitLoading(false);
                            errorToast(err?.message)
                        })
                }
            }
        }
        effectFunction()
    }, [stepIndex])

    return (
        <>
            <div className="row justify-content-center gy-5 w-100">
                <div className="col-sm-4 form-label">Backup Name</div>
                <div className="col-sm-8">{values?.jobName}</div>
                <div className="col-sm-4 form-label">Source</div>
                <div className="col-sm-8">{values?.chooseSource?.platformName}</div>
                <div className="col-sm-4 form-label">Destination</div>
                <div className="col-sm-8">{values?.chooseDestination?.platformName}</div>
                <div className="col-sm-4 form-label">Schedule</div>
                <div className="col-sm-8">
                    {cronstrue.toString(values?.cronString || "* * * * *")}
                    {cronCount && <div className="fw-bold">
                        (Estimated {cronCount} backup runs per month)
                    </div>}
                </div>
                <div className="col-sm-4 form-label">TimeZone</div>
                <div className="col-sm-8">{values?.timeZone?.value}</div>
                <div className="col-sm-4 form-label">Subscription</div>
                <div className="col-sm-8">
                    {loading ?
                        <LoaderSpinner /> :
                        <div className="border border-dashed border-primary rounded p-4 w-350px" >
                            <div className="d-flex align-items-center h-100 gap-5">
                                <div className="text-center">
                                    <div className="fs-1 fw-semibold" >{suitableProjectData?.metadata?.jobCredits || "-"}</div>
                                    <div>Runs</div>
                                </div>
                                <div className="border border-left-0 border-dashed border-primary h-50px" />
                                <div className="flex-fill">
                                    <div>For 30 days</div>
                                    <div className="my-2 border border-top-0 border-dashed border-primary " />
                                    <div>${(suitableProjectData?.price?.unit_amount || 0) / 100} <span className="text-uppercase">{suitableProjectData?.price?.currency}</span>/month</div>
                                </div>
                            </div>
                            <div className="bg-secondary shadow-sm rounded p-3 mt-4 ">
                                <div className="d-flex align-items-center gap-3">
                                    <SuccessIcon />
                                    <span>Maximum {suitableProjectData?.metadata?.jobCredits || "-"} automated scheduled backup</span>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </>
    )
}