import { useEffect, useState } from 'react'
import { getPlatforms } from '../../../../redux/Functions/PlatformFunctions'
import { errorToast } from '../../../../../_metronic/helpers/Utils'
import { platformsConstant } from '../../../../../_metronic/common/constants'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import idx from 'idx'
import { ErrorMessage, Field } from 'formik'
import SuccessIcon from '../../../../../_metronic/helpers/components/SuccessIcon'
import ErrorIcon from '../../../../../_metronic/helpers/components/ErrorIcon'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function JobAddStep3({ values, setValues, errors, isEdit }: any) {
    const navigate = useNavigate()
    const selectDestinationOptions = Object.values(platformsConstant)?.filter((val: any) => idx(platformsConstant, _ => _[values.platformSelect].supportedDestinationPlatforms)?.find((v: any) => v === val.name)
    )?.map((item: any) => ({ label: item.displayName, value: item.name }))
    const [platformData, setPlatformData] = useState<[] | any>([]);
    const [selectedDestination, setSelectedDestination] = useState(selectDestinationOptions[0]);
    const handleSelectAccount = (rowData: any) => {
        setValues({ ...values, chooseDestination: rowData })
        setSelectedDestination(rowData)
    }

    const fetchData = () => {
        getPlatforms()
            .then((res: any) => {
                setPlatformData(res.data);
            })
            .catch((err: any) => {
                errorToast(err?.message)
            })
    }

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
            <div className="row gy-5 w-100">
                <div className='col-sm-4' >
                    <label>Select Destination</label>
                    {/* <ReactSelect
                        options={selectDestinationOptions}
                        isClearable={false}
                        placeholder="Select Time Zone"
                        onChange={handleSelectAccount}
                        value={selectedDestination}
                    /> */}
                    <Field
                        as='select'
                        name='destinationSelect'
                        className='form-select mt-1'
                    >
                        {/* <option value='' hidden>Select Platform</option> */}
                        {Object.values(platformsConstant)?.
                            filter((val: any) => idx(platformsConstant, _ => _[values.platformSelect].supportedDestinationPlatforms)?.find((v: any) => v === val.name)
                            )?.
                            map((item: any, index: number) => {
                                return <option value={item.name} key={"ad" + index}>{item.displayName}</option>
                            })}
                    </Field>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='destinationSelect' />
                    </div>
                </div>
                <div className='col-sm-8'>
                    <label>Choose Account</label>
                    <div className="overflow-scroll border border-secondary mt-1" style={{ height: "325px" }} >
                        {platformData?.filter((val: any) => val.platform === values?.destinationSelect)?.map((item: any, index: number) => {
                            return (<>
                                <button className={`btn w-100 d-flex justify-content-start text-start align-items-center ${item.id === values?.chooseDestination?.id ? "btn-primary" : "btn-white"} bg-hover-light-primary p-5 cursor-pointer`} onClick={() => handleSelectAccount(item)} key={"ae" + index} disabled={item?.health !== "OK" || isEdit} >
                                    <div className='symbol symbol-50px me-5'>
                                        <img src={toAbsoluteUrl("/media/svg/brand-logos/" + idx(platformsConstant, _ => _[item?.platform].icon))} alt='logo' />
                                    </div>
                                    <div className='flex-grow-1'>
                                        <div className=' fw-bold fs-6'>
                                            {item?.platformName}
                                        </div>
                                        <span className='d-block fw-semibold'>{item?.id}</span>
                                    </div>
                                    {item?.health === "OK" ? <SuccessIcon color={`${item.id === values?.chooseDestination?.id ? "white" : "success"}`} /> : <ErrorIcon />}

                                </button>
                            </>)
                        })}
                        {!platformData?.filter((val: any) => val.platform === values?.destinationSelect).length && <><div className='text-center h-100'>
                            {values?.destinationSelect ?
                                <div className='d-flex h-100 align-items-center justify-content-center'>
                                    <Button className='mt-5' onClick={() => navigate('/platform/add')}>Add Platform</Button>
                                </div> :
                                "Please select platform"}
                        </div></>}
                    </div>
                    {errors?.chooseDestination?.platformName &&
                        <div className='text-danger mt-2'>
                            {errors?.chooseDestination?.platformName}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
