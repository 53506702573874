import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { ISftpAdd, sftpAddSchema } from '../../../../../_metronic/common/constants'
import { ApiPatch, ApiPost, errorToast, fileUpload, successToast } from '../../../../../_metronic/helpers/Utils'
import { useNavigate } from 'react-router-dom'
import { getPlatforms } from '../../../../redux/Functions/PlatformFunctions'
import { changePlatformDataById } from '../../../../redux/Slices/PlatformSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner'

export default function SftpServerAddModal({ sftpAddModal, setSftpAddModal, initData, isEdit }: any) {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const formik = useFormik<ISftpAdd>({
        initialValues: {
            host: "",
            username: "",
            password: "",
            port: 22,
            path: "",
            platformName: "",
            passphrase: "",
            privateKeyFile: "",
        },
        validationSchema: sftpAddSchema,
        onSubmit: (values) => {
            setLoading(true)
            const body = {
                host: values.host,
                username: values.username,
                password: values.password,
                port: values.port,
                path: values.path,
                platformName: values.platformName,
                passphrase: values.passphrase,
                privateKeyFile: values.privateKeyFile,
            }
            if (isEdit) {
                ApiPatch("/platform/sftp/update/" + initData.id, body)
                    .then((res: any) => {
                        successToast(res?.message);
                        getPlatforms({ platform_id: initData.id })
                            .then((data: any) => {
                                setLoading(false);
                                dispatch(changePlatformDataById(data?.data[0] || {}));
                                setSftpAddModal(false);
                            })
                            .catch((error: any) => {
                                errorToast(error?.message);
                                setSftpAddModal(false);
                            });
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    })
            } else {
                ApiPost("/platform/sftp/add", body)
                    .then((res: any) => {
                        successToast(res?.message);
                        navigate("/platform/list")
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    })

            }
        },
    })

    const handleFileChange = async (e: Event) => {
        setLoading(true);
        const fileVar = await fileUpload(e, "sftp");
        if (typeof fileVar === "string") {
            formik.setValues({ ...formik.values, privateKeyFile: fileVar }, true)
        } else if (fileVar === null) {
            formik.setValues({ ...formik.values, privateKeyFile: "" }, false)
        }
        setLoading(false);
    }

    const handleRemoveFile = () => formik.setValues({ ...formik.values, privateKeyFile: "" }, false)

    useEffect(() => {
        formik.setValues({ ...formik.values, ...initData })
    }, [initData])

    return (
        <>
            <Modal show={sftpAddModal} onHide={setSftpAddModal} backdrop="static" centered >
                <Modal.Header className='p-5' closeButton>
                    <Modal.Title>SFTP Server {isEdit ? "Update" : "Add"}</Modal.Title>
                </Modal.Header>
                <form autoComplete='off' onSubmit={formik.handleSubmit} noValidate className='form'>
                    <Modal.Body>
                        <div>
                            <label className='fw-bold fs-6 mb-1'>Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Give a name to your platform'
                                {...formik.getFieldProps('platformName')}
                            />
                            {formik.touched.platformName && formik.errors.platformName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.platformName}</div>
                                </div>
                            )}
                        </div>
                        <div className="d-flex gap-5 mt-3">
                            <div className='flex-fill'>
                                <label className='fw-bold fs-6'>Host</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Host'
                                    {...formik.getFieldProps('host')}
                                />
                                {formik.touched.host && formik.errors.host && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.host}</div>
                                    </div>
                                )}
                            </div>
                            <div className='w-90px'>
                                <label className='fw-bold fs-6'>Port</label>
                                <input
                                    type='number'
                                    className='form-control'
                                    placeholder='Port'
                                    {...formik.getFieldProps('port')}
                                />
                                {formik.touched.port && formik.errors.port && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.port}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Path</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Path'
                                {...formik.getFieldProps('path')}
                            />
                            {formik.touched.path && formik.errors.path && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.path}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3 mb-1'>Username</label>
                            <input
                                type='text'
                                className='form-control'
                                {...formik.getFieldProps('username')}
                            />
                            {formik.touched.username && formik.errors.username && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.username}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='fw-bold fs-6 mt-3'>Password</label>
                            <input
                                type='password'
                                autoComplete='new-password'
                                className='form-control'
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.password}</div>
                                </div>
                            )}
                        </div>
                        <div className='d-flex gap-5 mt-3'>
                            <div>
                                <label className='fw-bold fs-6 text-nowrap'>Private Key</label>

                                {formik.values.privateKeyFile ?
                                    <div role='button' onClick={handleRemoveFile}>
                                        <KTIcon iconName='delete-files' className='fs-3x mt-1 text-success' />
                                    </div> :
                                    <>
                                        <input
                                            type='file'
                                            className='form-control'
                                            onChange={(e: any) => handleFileChange(e)}
                                            disabled={loading}
                                        />
                                        {formik.touched.privateKeyFile && formik.errors.privateKeyFile && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.privateKeyFile}</div>
                                            </div>
                                        )}
                                    </>
                                }

                            </div>
                            <div className='w-100' >
                                <label className='fw-bold fs-6'>Passphrase </label>
                                <input
                                    type='password'
                                    autoComplete='new-password'
                                    className='form-control mb-lg-0'
                                    {...formik.getFieldProps('passphrase')}
                                />
                                {formik.touched.passphrase && formik.errors.passphrase && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.passphrase}</div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-3'>
                        <Button type='submit' className={`loaderBtn position-relative overflow-hidden ${loading ? "progress1" : ""}`} disabled={loading}>
                            {loading ? (
                                <LoaderSpinner text='Please wait...' />
                            ) : (
                                isEdit ? "Update" : <><KTIcon iconName='plus' className='fs-2' />Add</>
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
