import { ApiDelete, ApiGet, ApiPatch, ApiPost } from "../../../_metronic/helpers/Utils"

export const getJobs = (queryString?: object) => {
    return ApiGet("/jobs/get", queryString)
}

export const createJob = (body: object) => {
    return ApiPost("/jobs/add", body)
}

export const updateJob = (id: string, body: object) => {
    return ApiPatch("/jobs/update/" + id, body)
}

export const deleteJob = (id: string) => {
    return ApiDelete("/jobs/delete/" + id)
}

export const getJobHistory = (job_id: string, queryString?: object) => {
    return ApiGet("/jobs/history/" + job_id, queryString)
}

export const getJobCredits = (params: object) => {
    return ApiGet("/jobs/job-credits", params)
}