import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from '../Functions/CommonFunctions'

const initialState = {
    step: "overview",
    platformsDataById: {},
    isLoading: false,
    cronCount: 0
}

export const platformSlice = createSlice({
    name: 'platformData',
    initialState,
    reducers: {
        changeCronCount: (state, { payload }) => {
            state.cronCount = payload
        },
        changePlatformStep: (state, { payload }) => {
            state.step = payload
        },
        changePlatformDataById: (state, { payload }) => {
            state.platformsDataById = payload
        },
        changePlatformIsLoading: (state, { payload }) => {
            state.isLoading = payload
        },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const { changePlatformStep, changePlatformDataById, changePlatformIsLoading, changeCronCount } = platformSlice.actions

export default platformSlice.reducer