import { configureStore } from '@reduxjs/toolkit'
import ProfileSlice from './Slices/ProfileSlice'
import PlatformSlice from './Slices/PlatformSlice'
import JobSlice from './Slices/JobSlice'
import SubscriptionSlice from './Slices/SubscriptionSlice'

export const store = configureStore({
    reducer: {
        profileData: ProfileSlice,
        platformData: PlatformSlice,
        jobData: JobSlice,
        subscriptionData: SubscriptionSlice
    },
})

export type AppDispatch = typeof store.dispatch