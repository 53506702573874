import { ReactElement, useEffect, useState } from "react"
import { getJobHistory } from "../../../redux/Functions/JobsFunctions"
import {
  DayJS,
  LottieIconViewer,
  StatusBadges,
  errorToast,
} from "../../../../_metronic/helpers/Utils"
import { useNavigate } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import TableLoader from "../../../../_metronic/helpers/components/TableLoader"
import { jobHistoryStagesType, jobHistoryStatus } from "../../../../_metronic/common/constants"
import { KTIcon } from "../../../../_metronic/helpers"
import httpStatus from "http-status"
import runningProgressJSON from "../../../../_metronic/assets/lottie-animation/running-progress.json"

export default function JobView_Job_History_view() {
  const navigate = useNavigate()
  const history_id = new URLSearchParams(window.location.search).get("history_id")
  const job_id = new URLSearchParams(window.location.search).get("job_id")
  const [loading, setLoading] = useState(false)
  const [historyData, setHistoryData] = useState<any>({})

  const columns = [
    {
      dataField: "timestamp",
      text: "Time Stamp",
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='text-nowrap'>{DayJS(cell).format("DD/MM/YYYY hh:mm:ss A")}</div>
          </>
        )
      },
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell: any, row: any) => {
        let TypeIconComponent: any = "-"

        if (
          row?.metadata?.function_response?.status === httpStatus.REQUEST_TIMEOUT &&
          !row?.metadata?.retry?.isCompleted &&
          row?.metadata?.retry?.isRunning
        ) {
          TypeIconComponent = () => (
            <span title='Retrying'>
              <LottieIconViewer json={runningProgressJSON} height={27} width={27} />
            </span>
          )
        } else if (cell === jobHistoryStagesType?.info) {
          TypeIconComponent = () => (
            <span title='Information'>
              <KTIcon iconName='information-5' className='fs-1 text-primary' />
            </span>
          )
        } else if (cell === jobHistoryStagesType?.error) {
          TypeIconComponent = () => (
            <span title='Error'>
              <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
            </span>
          )
        } else if (cell === jobHistoryStagesType?.success) {
          TypeIconComponent = () => (
            <span title='Success'>
              <KTIcon iconName='check-circle' className='fs-1 text-success' />
            </span>
          )
        } else if (cell === jobHistoryStagesType?.warning) {
          TypeIconComponent = () => (
            <span title='Warning'>
              <KTIcon iconName='information-5' className='fs-1 text-warning' />
            </span>
          )
        }

        return (
          <>
            <TypeIconComponent />
          </>
        )
      },
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (cell: any, row: any) => {
        if (
          row?.metadata?.function_response?.status === httpStatus.REQUEST_TIMEOUT &&
          !row?.metadata?.retry?.isCompleted &&
          row?.metadata?.retry?.isRunning
        ) {
          cell = `${cell} [Retrying]`
        }

        return (
          <>
            <div>{cell}</div>
          </>
        )
      },
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (cell: any, row: any) => {
        if (
          row?.metadata?.function_response?.status === httpStatus.REQUEST_TIMEOUT &&
          !row?.metadata?.retry?.isCompleted &&
          row?.metadata?.retry?.isRunning
        ) {
          cell = `[Error ${row?.metadata?.function_response?.status || ""}]: ${cell}`
        }
        return (
          <>
            <div>{cell}</div>
          </>
        )
      },
    },
  ]

  const fetchHistoryByID = () => {
    setHistoryData({})
    setLoading(true)
    getJobHistory(job_id as string, {
      sortOrder: "desc",
      jobHistoryId: history_id,
      // limit: pageSize,
      // skip,
      // status: historyStatus,
    })
      .then((res: any) => {
        if (res?.data) {
          const historyLogsFilter = res?.data[0]?.logs?.filter((item: any) => {
            if (item?.metadata?.function_response?.status === httpStatus.REQUEST_TIMEOUT) {
              if (item?.metadata?.retry?.isCompleted) {
                return false
              }
            }
            return true
          })
          setHistoryData({ ...res?.data[0], logs: historyLogsFilter })
        }
        setLoading(false)
      })
      .catch((err) => {
        errorToast(err?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (history_id) {
      fetchHistoryByID()
    } else {
      errorToast("asd")
      navigate("/job/list")
    }
  }, [])

  return (
    <>
      <div className='card p-5'>
        <div className='row'>
          <div className='form-group col-sm-6'>
            <div>
              <label className='fw-bold text-muted'>Backup :</label>
              <span
                className='ps-8 fw-bold text-primary cursor-pointer'
                onClick={() => navigate("/job/view?tabStep=Overview&job_id=" + historyData?.jobId)}
              >
                {historyData?.job?.name || ""}
                {historyData?.jobId ? " (" + historyData?.jobId + ")" : "-"}
              </span>
            </div>
            <div className='mt-4'>
              <label className='fw-bold text-muted'>Start Time :</label>
              <span className='ps-2'>
                {historyData?.startTime
                  ? DayJS(historyData?.startTime).format("DD/MM/YYYY hh:mm:ss A")
                  : "-"}
              </span>
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <div>
              <label className='fw-bold text-muted pe-3'>Status</label>
              <StatusBadges
                type={historyData?.status ? jobHistoryStatus[historyData?.status] : null}
              />
            </div>
            <div className='mt-4'>
              <label className='fw-bold text-muted pe-3'>Result</label>
              <span className='ps-2'>
                {historyData?.logs?.length
                  ? historyData?.logs[historyData?.logs?.length - 1]?.message
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='card p-5 mt-5'>
        <BootstrapTable
          wrapperClasses='table-responsive'
          bordered={false}
          classes='table table-vertical-center overflow-hidden'
          bootstrap4
          keyField='id'
          data={historyData?.logs || []}
          columns={columns}
          noDataIndication={() => (loading ? <TableLoader /> : "No Records Found")}
          rowClasses={"border-bottom"}
        />
      </div>
    </>
  )
}
