import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getPlatforms, getZohoWorkDriveDirectory, updateZohoWorkDriveDirectory } from "../../../../redux/Functions/PlatformFunctions";
import { errorToast, successToast } from "../../../../../_metronic/helpers/Utils";
import LoaderSpinner from "../../../../../_metronic/helpers/components/LoaderSpinner";
import { useDispatch } from "react-redux";
import { changePlatformDataById, changePlatformIsLoading } from "../../../../redux/Slices/PlatformSlice";

export default function ZohoWorkDrivePlatformOverviewAccountInfoFolderSelectModal({ folderModal, setFolderModal, platformId, getAccountInformation }: any) {
    return (
        <>
            <Modal show={folderModal} onHide={setFolderModal} backdrop="static" centered >
                <Modal.Header className='p-5' closeButton>
                    <Modal.Title>Select Path</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-5 overflow-y-auto' style={{ maxHeight: "80vh" }}>
                    <FolderSelector platformId={platformId} getAccountInformation={getAccountInformation} setFolderModal={setFolderModal} />
                </Modal.Body>
            </Modal>
        </>
    )
}


const FolderSelector = ({ platformId, getAccountInformation, setFolderModal }: any) => {
    const dispatch = useDispatch()
    const [currentPath, setCurrentPath] = useState<any>([]);
    const [dataTemp, setDataTemp] = useState<any>([])
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)


    const handleFolderClick = async (folder: any) => {
        setCurrentPath([...currentPath, folder.title]);
        const response = await getDirectoryData(folder?.fetchNextToken)
        setDataTemp([...dataTemp, response])
    };
    const handleBackClick = () => {
        const newPath = [...currentPath];
        newPath.pop();
        setCurrentPath(newPath);
        setData(dataTemp[dataTemp.length - 2])
        const tempArr = [...dataTemp]
        tempArr.splice(tempArr.length - 1, 1)
        setDataTemp(tempArr)
    };

    const handleSelect = async (folder: any) => {
        if (updateLoading) return;
        setUpdateLoading(true)
        updateZohoWorkDriveDirectory(platformId, {
            upload_folder_id: folder.id,
        })
            .then(() => {
                setFolderModal(false)
                getAccountInformation()
                getPlatforms({ platform_id: platformId })
                    .then((data: any) => {
                        setData(data?.data[0] || {});
                        dispatch(changePlatformDataById(data?.data[0] || {}));
                        dispatch(changePlatformIsLoading(false))
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        dispatch(changePlatformIsLoading(false))
                    });
                setUpdateLoading(false)
            })
            .catch(error => {
                errorToast(error.message)
                setUpdateLoading(false)
            })
    }

    const getDirectoryData = async (fetchNextToken?: any) => {
        setLoading(true)
        return await getZohoWorkDriveDirectory({ platformId, fetchNextToken })
            .then((res: any) => {
                setData(res?.data || [])
                if (!dataTemp.length) {
                    setDataTemp([res?.data] || [])
                }
                setLoading(false);
                return res?.data
            })
            .catch((err: any) => { setLoading(false); errorToast(err.message) })
    }

    useEffect(() => {
        getDirectoryData()
    }, [])

    return (
        <div>
            {currentPath.length > 0 && (
                <div className="path d-flex justify-content-between align-items-center mb-5">
                    <div>
                        Path : /{currentPath.join('/')}
                    </div>
                    <div>
                        <Button size="sm" onClick={handleBackClick} disabled={loading}>Back</Button>
                    </div>
                </div>
            )}
            <ul className="list-group list-unstyled">
                <li className="bg-secondary py-2 px-5 fw-bold">
                    Folder Name
                </li>
                {loading ?
                    <div className="fs-1 text-center py-5"> <LoaderSpinner md /></div>
                    : data?.items?.length ? data.items.map((folder: any) => (
                        <>
                            <li
                                key={folder.id}
                                className="bg-light  d-flex justify-content-between align-items-center"
                            >
                                <div
                                    className={`w-100 py-2 px-5 ${folder?.fetchNextToken ? "bg-hover-primary bg-light-success text-hover-white" : ""}`}
                                    onClick={() => {
                                        if (folder?.fetchNextToken) {
                                            handleFolderClick(folder)
                                        }
                                    }}
                                >
                                    {folder.title}
                                </div>
                                <div className="text-hover-white bg-hover-success px-5 py-2 rounded-3 cursor-pointer" onClick={() => handleSelect(folder)}>{updateLoading ? "loading" : "Select"}</div>
                            </li>
                        </>
                    )) : <div className="fw-semibold fs-3 text-center py-5">No Folder Found</div>}
            </ul>
        </div>
    );
};