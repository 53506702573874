import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { useEffect, useState } from "react";
import LoaderSpinner from "../../../../_metronic/helpers/components/LoaderSpinner";
import { getJobs } from "../../../redux/Functions/JobsFunctions";
import { errorToast } from "../../../../_metronic/helpers/Utils";
import JobView_Overview from "./JobView_Overview";
import { changeJobDataById, changeJobIsLoading, changeJobStep } from "../../../redux/Slices/JobSlice";
import JobView_Job_History from "./JobView_Job_History";
import JobView_Subscription from "./JobView_Subscription";
import JobView_Transactions from "./JobView_Transactions";
import { getSubscription } from "../../../redux/Functions/SubscriptionFuntions";
import { Button } from "react-bootstrap";

export default function JobView() {
    const { step } = useSelector((state: any) => state.jobData);
    const dispatch = useDispatch<AppDispatch>();
    const tabStepQ = new URLSearchParams(window.location.search).get('tabStep');
    useEffect(() => {
        if (tabStepQ) {
            dispatch(changeJobStep(tabStepQ))
        } else {
            dispatch(changeJobStep("Overview"))
        }
    }, [])
    return (<>
        <JobViewHeader />
        {step === "Overview" && <JobView_Overview />}
        {step === "Backup history" && <JobView_Job_History />}
        {step === "Subscription" && <JobView_Subscription />}
        {step === "Transactions" && <JobView_Transactions />}
    </>)
}


function JobViewHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { step, isLoading, jobDataById: data } = useSelector((state: any) => state.jobData);
    const searchParams = new URLSearchParams(window.location.search);
    const job_id: any = new URLSearchParams(window.location.search).get("job_id")
    const [subsLoading, setSubsLoading] = useState(false)
    const [subscriptionData, setSubscriptionData] = useState<any>([])

    const handleChangeTab = (tabName: string) => {
        searchParams.set("tabStep", tabName)
        dispatch(changeJobStep(tabName));
        navigate(window.location.pathname + "?" + searchParams.toString());

    }

    useEffect(() => {
        if (!job_id) {
            errorToast("Backup ID is not found")
            navigate("/job/list")
        }
        setSubsLoading(true)
        getSubscription(
            {
                jobId: job_id,
                sortOrder: "desc",
                sortBy: "createdAt",
                // limit: pageSize,
                // skip,
            })
            .then((res: any) => {
                setSubsLoading(false)
                setSubscriptionData(res?.data)
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setSubsLoading(false);
            })
        dispatch(changeJobIsLoading(true));
        getJobs({ id: job_id })
            .then((res: any) => {
                dispatch(changeJobDataById(res?.data[0] || {}));
                dispatch(changeJobIsLoading(false));
            })
            .catch((res: any) => {
                errorToast(res?.message)
                dispatch(changeJobIsLoading(false));
                navigate("/job/list")
            })
    }, [])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-7 pb-0'>
                <div className='d-flex justify-content-between align-items-center'>
                    {isLoading ?
                        <LoaderSpinner />
                        : <>
                            <h3>{data?.name}</h3>
                        </>
                    }
                    {subsLoading ? <LoaderSpinner /> : (subscriptionData?.length > 0 && subscriptionData[0]?.status !== "active" && step !== 'Subscription') &&
                        <Button size="sm" onClick={() => handleChangeTab("Subscription")} >Finish Payment</Button>}
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap '>
                        <li className='nav-item'>
                            <div
                                className={
                                    `nav-link cursor-pointer text-active-primary me-5 ` +
                                    (step === 'Overview' && 'active')
                                }
                                onClick={() => handleChangeTab("Overview")}
                            >
                                Overview
                            </div>
                        </li>
                        <li className='nav-item'>
                            <div
                                className={
                                    `nav-link cursor-pointer text-active-primary me-5 ` +
                                    (step === 'Backup history' && 'active')
                                }
                                onClick={() => handleChangeTab("Backup history")}
                            >
                                Backup history
                            </div>
                        </li>
                        <li className='nav-item'>
                            <div
                                className={
                                    `nav-link cursor-pointer text-active-primary me-5 ` +
                                    (step === 'Subscription' && 'active')
                                }
                                onClick={() => handleChangeTab("Subscription")}
                            >
                                Subscription
                            </div>
                        </li>
                        <li className='nav-item'>
                            <div
                                className={
                                    `nav-link cursor-pointer text-active-primary me-5 ` +
                                    (step === 'Transactions' && 'active')
                                }
                                onClick={() => handleChangeTab("Transactions")}
                            >
                                Transactions
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}