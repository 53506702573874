import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useEffect, useState } from 'react'
import { getPlatformsHealthCount } from '../../redux/Functions/PlatformFunctions'
import { KTIcon } from '../../../_metronic/helpers'
import { errorToast } from '../../../_metronic/helpers/Utils'
import LoaderSpinner from '../../../_metronic/helpers/components/LoaderSpinner'
import { Link } from 'react-router-dom'
// import {
//   ListsWidget1,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   MixedWidget10,
//   MixedWidget11,
//   MixedWidget2,
//   StatisticsWidget5,
//   TablesWidget10,
//   TablesWidget5,
// } from '../../../_metronic/partials/widgets'

const DashboardPage = () => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    getPlatformsHealthCount()
      .then((res: any) => {
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        errorToast(err?.message)
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (<>
    <div className="d-flex gap-8 flex-wrap">
      <div className="w-400px mb-xl-10">
        <div className="card card-flush h-xl-100">
          <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" style={{ backgroundImage: "url('/media/auth/bg7-dark.jpg')" }} data-bs-theme="light">
            <span className="fw-bold fs-2x mb-3 text-white pt-15" style={{ textShadow: "0 0 7px black" }}>Platforms</span>
          </div>
          <div className="card-body mt-n20">
            <div className="mt-n20 position-relative">
              <div className="row g-3 g-lg-6">
                <div className="col-12">
                  <Link to="/platform/list" className="bg-light-primary d-block rounded-2 px-6 py-5 cursor-pointer">
                    <KTIcon iconName='element-plus' className='fs-1 text-primary' />
                    <div className="mt-3">
                      <span className="text-primary fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">{isLoading ? <LoaderSpinner md /> : data?.totalPlatform || "0"}</span>
                      <span className="text-primary fw-semibold fs-6">Total Platforms</span>
                    </div>
                  </Link>
                </div>
                <div className="col-6">
                  <Link to="/platform/list" className="bg-light-success d-block rounded-2 px-6 py-5 cursor-pointer">
                    <KTIcon iconName='shield-tick' className='fs-2x text-success' />
                    <div className="mt-3">
                      <span className="text-success fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">{isLoading ? <LoaderSpinner md /> : data?.goodHealth || "0"}</span>
                      <span className="text-success fw-semibold fs-6">Healthy Platforms</span>
                    </div>
                  </Link>
                </div>
                <div className="col-6">
                  <Link to="/platform/list" className="bg-light-danger d-block rounded-2 px-6 py-5 cursor-pointer h-100">
                    <KTIcon iconName='shield-cross' className='fs-2x text-danger' />
                    <div className="mt-3">
                      <span className="text-danger fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">{isLoading ? <LoaderSpinner md /> : data?.badHealth || "0"}</span>
                      <span className="text-danger fw-semibold fs-6">Bad Health</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="w-400px mb-xl-10">
        <div className="card card-flush h-xl-100">
          <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" style={{ backgroundImage: "url('/media/auth/bg7-dark.jpg')" }} data-bs-theme="light">
            <span className="fw-bold fs-2x mb-3 text-white pt-15" style={{ textShadow: "0 0 7px black" }}>Backups</span>
          </div>
          <div className="card-body mt-n20">
            <div className="mt-n20 position-relative">
              <div className="row g-3 g-lg-6">
                <div className="col-12">
                  <Link to="/job/list" className="bg-light-primary d-block rounded-2 px-6 py-5 cursor-pointer">
                    <KTIcon iconName='element-plus' className='fs-1 text-primary' />
                    <div className="mt-3">
                      <span className="text-primary fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">{isLoading ? <LoaderSpinner md /> : data?.totaljobs || "0"}</span>
                      <span className="text-primary fw-semibold fs-6">Total Backups</span>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <Link to="/job/list" className="bg-light-success d-block rounded-2 px-6 py-5 cursor-pointer">
                    <KTIcon iconName='shield-tick' className='fs-2x text-success' />
                    <div className="mt-3">
                      <span className="text-success fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">{isLoading ? <LoaderSpinner md /> : data?.activeJobs || "0"}</span>
                      <span className="text-success fw-semibold fs-6">Active Backups</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-400px">
        <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10 bg-danger bg-opacity-75" style={{ backgroundImage: "url('/metronic8/demo1/assets/media/patterns/vector-1.png')" }}>
          <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
              <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{data?.totaljobs || "0"}</span>
              <span className="text-white opacity-75 pt-1 fw-semibold fs-6">Backup Counts</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </>)
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
