import { Navigate, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import PlatformAdd from './components/PlatformAdd'
import PlatformCallback from './components/PlatformCallback'
import PlatformList from './components/PlatformList'
import PlatformViewDetails from './components/PlatformViewDetails'

const platFormBreadCrumbs = [
    {
        title: 'Platforms',
        path: '/platform/list',
        isSeparator: false,
        isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];

const PlatformPage = () => (
    <Routes>
        <Route
            path='list'
            element={
                <>
                    <PageTitle breadcrumbs={[]} >Platforms List</PageTitle>
                    <PlatformList />
                </>
            }
        />
        <Route
            path='add'
            element={
                <>
                    <PageTitle breadcrumbs={platFormBreadCrumbs}>Add Platform</PageTitle>
                    <PlatformAdd />
                </>
            }
        />
        <Route
            path=':name/callback'
            element={
                <>
                    <PlatformCallback />
                </>
            }
        />
        <Route
            path='view'
            element={
                <>
                    <PageTitle breadcrumbs={platFormBreadCrumbs}>Platform Details</PageTitle>
                    <PlatformViewDetails />
                </>
            }
        />
        <Route
            path='connections'
            element={
                <>
                    <PageTitle breadcrumbs={[]}>Connections</PageTitle>
                </>
            }
        />
        <Route index element={<Navigate to='/platform/list' />} />
    </Routes>
)

export default PlatformPage
