import Pagination from "rc-pagination";
import { useState } from "react";
import ReactSelect from "react-select";
import { historyStatusOptions, pageLimitOptions } from "../common/constants";

interface IPagination {
    total: number;
    pageSize: number;
    setSkip: any;
    currentPage: number;
    setCurrentPage: any;
}

export function PaginationCustom({ total, pageSize, setSkip, currentPage, setCurrentPage }: IPagination) {

    return (<>
        <Pagination
            total={total}
            current={currentPage}
            pageSize={pageSize}
            onChange={(newCurrent: number) => {
                setCurrentPage(newCurrent);
                setSkip((newCurrent - 1) * pageSize);
            }}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
    </>)
}

interface IPageSize {
    setPageSize: any;
}

export function PageSizeCustom({ setPageSize }: IPageSize) {
    const [currentPageSizeConvert, setCurrentPageSizeConvert] = useState({ label: "10", value: 10 })

    return (<>
        <ReactSelect
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    width: '80px',
                }),
            }}
            options={pageLimitOptions}
            value={currentPageSizeConvert}
            onChange={({ value }: any) => {
                setPageSize(value);
                setCurrentPageSizeConvert({ label: `${value}`, value: value });
            }}
            isSearchable={false}
        />
    </>)
}

interface IStatus {
    setHistoryStatus: any;
}
export function HistoryStatusCustom({ setHistoryStatus }: IStatus) {
    const [historyStatusConvert, setHistoryStatusConvert] = useState<any>(null)

    return (<>
        <ReactSelect
            placeholder="Select Status"
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    width: '150px',
                }),
                clearIndicator: (provided, state) => ({
                    ...provided,
                    '&:hover': {
                        color: 'var(--bs-danger)',
                    },
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    '&:hover': {
                        color: 'var(--bs-primary)',
                    },
                }),
            }}
            options={historyStatusOptions}
            value={historyStatusConvert}
            onChange={(val: any) => {
                if (val) {
                    setHistoryStatus(val.value || "");
                    setHistoryStatusConvert({ label: val.label, value: val.value })
                } else {
                    setHistoryStatus("");
                    setHistoryStatusConvert(null)
                }
            }}
            isSearchable={false}
            isClearable={true}
        />
    </>)
}


