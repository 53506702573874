import { toAbsoluteUrl } from "../AssetHelpers";
import SuccessIcon from "./SuccessIcon";
import ErrorIcon from "./ErrorIcon";

interface ITile {
    icon: string;
    title: string;
    subTitle?: string;
    health?: string;
    onClick?: () => void;
}

export default function PlatformTile(item: ITile) {
    return (
        <div className="d-inline-flex gap-5 justify-content-start text-start align-items-center bg-light-primary p-3 rounded-3" role={item.onClick ? "button" : "none"} onClick={item.onClick}>
            <div className='symbol symbol-50px'>
                <img src={toAbsoluteUrl("/media/svg/brand-logos/" + item.icon)} alt='logo' />
            </div>
            <div className='flex-grow-1'>
                <div className='fw-bold fs-6'>
                    {item?.title}
                </div>
                <span className='d-block text-muted fw-semibold'>{item?.subTitle}</span>
            </div>
            {item?.health ?
                item?.health === "OK" ? <SuccessIcon /> : <ErrorIcon />
                : ""}
        </div>
    )
}
