import { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { ImCancelCircle } from 'react-icons/im'
import { errorToast } from '../../../../_metronic/helpers/Utils';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import TableLoader from '../../../../_metronic/helpers/components/TableLoader';
import { zohoAuthCallback, zohoWorkDriveAuthCallback } from '../../../redux/Functions/PlatformFunctions';
import { platformNames } from '../../../../_metronic/common/constants';

export default function PlatformCallback() {
    const navigate = useNavigate()
    const [status, setStatus] = useState<"loading" | "success" | "failed">("loading");
    const queryString = new URLSearchParams(window.location.search)
    const [authorizedID, setAuthorizedID] = useState("")
    const qState = queryString.get("state")

    useEffect(() => {
        if (!queryString.get("code") || !queryString.get("location") || queryString.get("account-server") || !qState) {
            return setStatus('failed');
        }
        const body = {
            code: queryString.get("code"),
            location: queryString.get("location"),
            accountServer: queryString.get("accounts-server")
        }
        switch (qState) {
            case "zoho":
                zohoAuthCallback(body)
                    .then(() => {
                        setStatus("success")
                    })
                    .catch((err: any) => {
                        errorToast(err?.message);
                        navigate("/platform/add")
                        setStatus('failed')
                    })
                break;
            case "Zoho WorkDrive":
                zohoWorkDriveAuthCallback(body)
                    .then((res: any) => {
                        setAuthorizedID(res?.data?.platformId)
                        setStatus("success")
                    })
                    .catch((err: any) => {
                        errorToast(err?.message);
                        navigate("/platform/add")
                        setStatus('failed')
                    })
                break;
        }
    }, [])

    return (
        <div className='d-flex flex-column gap-10 justify-content-center align-items-center w-100 h-100'>
            {status === "loading" ?
                <TableLoader /> :
                status === "success" ?
                    (<>
                        <FaCheck fontSize={100} className="text-success" />
                        <h1>You have authorized Zoho {qState === platformNames.zohoWorkDrive ? "Work Drive" : null} successfully.</h1>
                        <Button href={qState === platformNames.zohoWorkDrive ? '/platform/view?platform=Zoho%20WorkDrive&platform_id=' + authorizedID : '/platform/list'}>View</Button>
                    </>) :
                    status === "failed" ?
                        (<>
                            <ImCancelCircle fontSize={100} className="text-danger" />
                            <h1>Authorization Failed !</h1>
                        </>) :
                        null
            }

        </div>
    )
}
