import { createAction } from "@reduxjs/toolkit";
import { loadStripe } from "@stripe/stripe-js";

export const revertAll = createAction('RESET_ALL')

export const LoadStripeFunction = () => {
    const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    if (stripePublishableKey) {
        return loadStripe(stripePublishableKey, {})
    } else {
        return null
    }
}