import { useEffect, useState } from 'react'
import { DayJS, errorToast, successToast } from '../../../../_metronic/helpers/Utils';
import { getRequiredCreditPayment, getStripePublishableKey, getTransaction, getTransactionInvoice } from '../../../redux/Functions/SubscriptionFuntions';
import { PageSizeCustom, PaginationCustom } from '../../../../_metronic/helpers/JobHistoryFilters';
import BootstrapTable from 'react-bootstrap-table-next';
import TableLoader from '../../../../_metronic/helpers/components/TableLoader';
import { cardTypeConstants, currencySymbols, stripePublishableKey, transactionStatus } from '../../../../_metronic/common/constants';
import { Button, Modal, NavDropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from '../../Jobs/components/jobAddSteps/StripeCheckoutForm';
import { changeStripePublishableKey } from '../../../redux/Slices/SubscriptionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import SuccessIcon from '../../../../_metronic/helpers/components/SuccessIcon';
import { changeJobIdForPaymentSuccess } from '../../../redux/Slices/JobSlice';
import { useNavigate } from 'react-router-dom';
import { IoMdDownload } from "react-icons/io";
import ErrorIcon from '../../../../_metronic/helpers/components/ErrorIcon';
import SVG from 'react-inlinesvg'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getPaymentMethods, deletePaymentMethod, updatePaymentMethod, addDefaultPaymentMethod } from '../../../redux/Functions/BillingFunctions';
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner';
import { LoadStripeFunction } from '../../../redux/Functions/CommonFunctions';
import Swal from 'sweetalert2';

export default function BillingList() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [cardLoading, setCardLoading] = useState(false);
    const [addCardLoading, setAddCardLoading] = useState(false)
    const [data, setData] = useState<any>([]);
    const [cardData, setCardData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [skip, setSkip] = useState(0);
    const [timer, setTimer] = useState(0)
    const [payLoading, setPayLoading] = useState(false)
    const [stripeCheckoutModal, setStripeCheckoutModal] = useState(false);
    const [stripeData, setStripeData] = useState<any>({})
    const [isShowMore, setIsShowMore] = useState(false)

    const loadStripePromise = LoadStripeFunction()

    const columns = [
        {
            dataField: 'no',
            text: 'No',
            formatter: (cell: any, row: any, index: number) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {index + 1 + skip}
                    </div>
                </>)
            }
        },
        {
            dataField: 'id',
            text: 'Transaction ID',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {cell || "-"}
                    </div>
                </>)
            }
        },
        {
            dataField: 'job',
            text: 'Backup Name',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center text-nowrap text-primary cursor-pointer" onClick={() => { if (cell?.id) { navigate("/job/view?tabStep=Transactions&job_id=" + cell?.id) } }}>
                        {cell?.name || "-"}
                    </div>
                </>)
            }
        },
        {
            dataField: 'amount',
            text: 'Amount',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {cell ? <>{currencySymbols[row?.currency.toUpperCase() || "-"] || ''}{Number(cell) / 100} {row?.currency.toUpperCase() || "-"}</> : "-"}
                    </div>
                </>)
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            formatter: (cell: any, row: any) => {
                return (<>
                    <div className="d-flex align-items-center">
                        {DayJS(cell).format('DD/MM/YYYY hh:mm A')}
                    </div>
                </>)
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell: any, row: any) => {
                if (cell === transactionStatus.paymentRequire) {
                    return <Button size='sm' onClick={() => handlePay(row.id, row?.metadata?.jobId)} disabled={payLoading} >Pay</Button>
                }
                if (cell === transactionStatus.pending) {
                    return <OverlayTrigger
                        trigger="hover"
                        placement="top-start"
                        overlay={<Popover className='p-3'>
                            Pending
                        </Popover>}
                    >
                        <div className="position-relative badge badge-light-warning badge-lg">
                            <KTIcon iconName='time' className='fs-3 me-1 text-warning' /> <span>Pending</span>
                        </div>
                    </OverlayTrigger>
                }
                if (cell === transactionStatus.success) {
                    return <div className="position-relative badge badge-light-success badge-lg">
                        <KTIcon iconName='check' className='fs-3 text-success me-1' /> <span>Completed</span>
                    </div>
                }
                if (cell === transactionStatus.failed) {
                    return <OverlayTrigger
                        trigger="hover"
                        placement="top-start"
                        overlay={<Popover className='p-3'>
                            {row?.message}
                        </Popover>}
                    >
                        <div className="position-relative badge badge-light-danger badge-lg">
                            <KTIcon iconName='cross' iconType='solid' className='fs-3 text-danger me-1' /> <span>{row?.message}</span>
                        </div>
                    </OverlayTrigger>
                }
                if (cell === transactionStatus.refunded) {
                    return <span title='Refunded'><KTIcon iconName='rescue' className='fs-2x text-primary' /></span>
                }
            }
        },
        {
            dataField: 'gatewayMetadata',
            text: 'Action',
            formatter: (cell: any, row: any, index: number) => {
                return (
                    <>
                        <div
                            className='px-2 pt-2 pb-3 rounded-3 text-nowrap d-inline-block bg-light text-primary bg-hover-primary text-hover-white'
                            onClick={async () => {
                                const invoiceURL: any = await getTransactionInvoice({ transactionId: row?.id })
                                if (invoiceURL?.data?.length) {
                                    if (!invoiceURL?.data[0]?.invoice?.hosted_invoice_url) {
                                        return errorToast("Invoice not found")
                                    }
                                    window.open(invoiceURL?.data[0]?.invoice?.hosted_invoice_url)
                                } else {
                                    errorToast("Invoice not found")
                                }
                            }}
                        >
                            <IoMdDownload className='fs-1' /> Invoice
                        </div>
                    </>
                )
            }
        },
    ]

    const handlePay = (Id: string, job_id: string) => {
        setPayLoading(true);
        dispatch(changeJobIdForPaymentSuccess(job_id))
        getRequiredCreditPayment(Id)
            .then((res: any) => {
                setStripeData(res?.data)
                setPayLoading(false);
                if (res?.data?.clientSecret) {
                    setStripeCheckoutModal(true);
                } else {
                    errorToast("Stripe initialization failed")
                }

            }).catch((err: any) => {
                setPayLoading(false);
                errorToast(err.message);
            })
    }

    const fetchJobTransactions = () => {
        setLoading(true);
        setData([])
        getTransaction(
            {
                sortOrder: "desc",
                sortBy: "createdAt",
                limit: pageSize,
                skip,
                expand: "job"
            })
            .then((res: any) => {
                setData(res?.data)
                setLoading(false);
                setTotalCount(res?.metadata?.count)
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setLoading(false);
            })
    }

    const fetchPaymentMethods = () => {
        if (!cardData?.length) { setCardLoading(true) }
        getPaymentMethods()
            .then((res: any) => {
                if (res?.data?.length) {
                    setCardData(res?.data)
                }
            })
            .catch((err: any) => {
                errorToast(err?.message)
            })
            .finally(() => {
                setCardLoading(false)
            })
    }

    const handleDeleteCard = async (ID: string) => {
        await Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this card?",
            showCancelButton: true,
            confirmButtonColor: "#f14141",
            confirmButtonText: "Delete",
            cancelButtonColor: "transparent",
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await deletePaymentMethod(ID)
                    .then(() => {
                        successToast("Card deleted successfully")
                        fetchPaymentMethods()
                    })
                    .catch((err: any) => {
                        errorToast(err?.message)
                    })
            },
            customClass: {
                title: "fs-5"
            },
        })
    }

    const handleSetAsDefault = async (ID: string) => {
        await Swal.fire({
            icon: "info",
            title: "Are you sure you want to set as default?",
            showCancelButton: true,
            confirmButtonColor: "#009ef7",
            confirmButtonText: "Yes, Set as default",
            cancelButtonColor: "transparent",
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await updatePaymentMethod({ stripe_payment_method_id: ID })
                    .then(() => {
                        successToast("Card set as default successfully")
                        fetchPaymentMethods()
                    })
                    .catch((err: any) => {
                        errorToast(err?.message)
                    })
            },
            customClass: {
                title: "fs-5"
            },
        })
    }

    const handleAddPaymentMethod = async () => {
        setAddCardLoading(true)
        try {
            const paymentData: any = await addDefaultPaymentMethod({
                success_url: window.location.origin + "/billing/list",
                // return_url: window.location.origin + "/billing/list",
                ui_mode: "hosted"
            });
            const stripe: any = await loadStripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: paymentData?.data?.id,
            });
            errorToast(error)
            setAddCardLoading(false)
            // setStripeData({ ...stripeData, clientSecret: paymentData?.data?.client_secret })
            // setStripeCheckoutModal(true)
        } catch (err: any) {
            setAddCardLoading(false)
            errorToast(err?.message)
        }
    };

    useEffect(() => {
        clearTimeout(timer);
        const newTimer: any = setTimeout(() => {
            fetchJobTransactions();
            fetchPaymentMethods();
        }, 100);
        setTimer(newTimer);
        // if (!stripePublishableKey) {
        //     getStripePublishableKey()
        //         .then((res: any) => {
        //             dispatch(changeStripePublishableKey(res?.data?.publishable_key))
        //         })
        //         .catch((err: any) => {
        //             errorToast(err?.message)
        //         })
        // }
    }, [skip, pageSize])

    return (<>
        <div className='mb-5 d-flex justify-content-end'>
            <Button onClick={handleAddPaymentMethod} disabled={addCardLoading}>{addCardLoading ? <LoaderSpinner text='Loading...' /> : "Add New Card"}</Button>
        </div>
        <div className="d-flex flex-wrap gap-5 mb-5">
            {cardLoading ?
                <div><LoaderSpinner md text='Card retrieving...' /></div>
                : cardData?.slice(0, isShowMore ? cardData?.length : 3)?.map((value: any, index: number) => {
                    return <div className="card_main" key={index}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <img src={toAbsoluteUrl(`/media/svg/card-logos/${cardTypeConstants[value?.card?.brand]}.svg`)} alt={value?.card?.brand} />
                            </div>
                            <div className='d-flex align-items-start'>
                                {value?.is_default_payment_method ? <div className='bg-warning text-white fw-semibold px-3 py-1 rounded-4'>Default</div> : null}
                                <NavDropdown className='dropdownToggleRemove' title={<KTIcon iconName='dots-vertical' iconType='solid' className='fs-2x text-white' />}>
                                    <NavDropdown.Item onClick={() => handleSetAsDefault(value?.stripe_payment_id)}>Set as default</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleDeleteCard(value?.stripe_payment_id)}>
                                        Delete
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </div>
                        <div>
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <h4 className='text-secondary fw-bold'>{value?.billing_details?.name || ""}</h4>
                                <h4 className='text-secondary fw-bolder'>{value?.card?.exp_month.toString().padStart(2, '0') || "-"}/{value?.card?.exp_year.toString().padStart(4, '0') || "-"}</h4>
                            </div>
                            <div className='fs-3 fw-bolder'>**** **** **** {value?.card?.last4 || "XXXX"}</div>
                        </div>
                    </div>
                })}
        </div>
        {cardData?.length > 3 && <div
            className='text-primary cursor-pointer text-decoration-underline mb-5 fw-bold fs-3 ms-5'
            onClick={() => setIsShowMore(!isShowMore)}>
            Show {isShowMore ? "Less" : "More"}
        </div>}
        <div className="card p-5">
            <h2 className='fw-bolder mb-5 pb-3 border-bottom border-secondary'>Transactions</h2>
            <div className="filters d-flex mb-3 gap-5">
                <div>
                    <label className='fw-semibold mb-1'>Count</label>
                    <PageSizeCustom setPageSize={setPageSize} />
                </div>
            </div>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-vertical-center overflow-hidden"
                bootstrap4
                keyField='id'
                data={data || []}
                columns={columns}
                noDataIndication={() => loading ? <TableLoader /> : "No Records Found"}
                rowClasses={"border-bottom"}
            />
            <div className='text-end mt-3'>
                <PaginationCustom total={totalCount} pageSize={pageSize} setSkip={setSkip} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </div>
        <Modal show={stripeCheckoutModal} backdrop="static" onHide={() => setStripeCheckoutModal(false)} centered >
            <Modal.Header closeButton> <Modal.Title>Add Subscription</Modal.Title> </Modal.Header>
            <Modal.Body >
                <Elements stripe={loadStripePromise} options={{ clientSecret: stripeData?.clientSecret }}   >
                    <StripeCheckoutForm values={{ stripeData }} />
                </Elements>
            </Modal.Body>
        </Modal>
    </>)
}
