import { useEffect, useState } from 'react'
import { getPlatforms } from '../../../../redux/Functions/PlatformFunctions'
import { errorToast } from '../../../../../_metronic/helpers/Utils'
import { platformsConstant } from '../../../../../_metronic/common/constants'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import idx from 'idx'
import { ErrorMessage, Field } from 'formik'
import SuccessIcon from '../../../../../_metronic/helpers/components/SuccessIcon'
import ErrorIcon from '../../../../../_metronic/helpers/components/ErrorIcon'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

export default function JobAddStep2({ values, setValues, errors, isEdit }: any) {
    const navigate = useNavigate()
    const selectSourceOptions = Object.values(platformsConstant)?.filter((val: any) => val.isSource).map((item: any) => ({ label: item.displayName, value: item.name }))
    const [platformData, setPlatformData] = useState<[] | any>([]);
    const [selectedSource, setSelectedSource] = useState(selectSourceOptions[0])
    const handleSelectAccount = (rowData: any) => {
        setValues({ ...values, chooseSource: rowData })
        setSelectedSource(rowData)
    }

    const fetchData = () => {
        getPlatforms()
            .then(async (res: any) => {
                setPlatformData(res?.data);
                if (!isEdit && res?.data?.length) {
                    let destinationPlatformArray = idx(platformsConstant, (_) => _[values?.platformSelect].supportedDestinationPlatforms) || []
                    for (let i = 0; i < destinationPlatformArray.length; i++) {
                        const findObject = await res.data.find((v: any) => v?.platform === destinationPlatformArray[i])
                        if (findObject) {
                            setValues({ ...values, destinationSelect: findObject?.platform })
                            break;
                        }
                    }
                }
            })
            .catch((err: any) => {
                errorToast(err?.message)
            })
    }

    useEffect(() => {
        fetchData();
    }, [values?.platformSelect])
    return (
        <>
            <div className="row gy-5 w-100">
                <div className='col-sm-4' >
                    <label>Select Platform</label>
                    {/* <ReactSelect
                        options={selectSourceOptions}
                        isClearable={false}
                        placeholder="Select Time Zone"
                        onChange={handleSelectAccount}
                        value={selectedSource}
                    /> */}
                    <Field
                        as='select'
                        name='platformSelect'
                        className='form-select mt-1'
                    >
                        {/* <option value='' hidden>Select Platform</option> */}
                        {Object.values(platformsConstant)?.filter((val: any) => val.isSource).map((item: any, index: number) => {
                            return <option value={item.name} key={"aa" + index}>{item.displayName}</option>
                        })}
                    </Field>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='platformSelect' />
                    </div>
                </div>
                <div className='col-sm-8'>
                    <label>Choose Account</label>
                    <div className="overflow-scroll border border-secondary mt-1" style={{ height: "325px" }} >
                        {platformData?.filter((val: any) => val.platform === values?.platformSelect)?.map((item: any, index: number) => {
                            return (<>
                                <button className={`btn w-100 d-flex justify-content-start text-start align-items-center ${item.id === values?.chooseSource?.id ? "btn-primary" : "btn-white"} bg-hover-light-primary p-5 cursor-pointer`} onClick={() => handleSelectAccount(item)} key={"ab" + index} disabled={item?.health !== "OK" || isEdit} >
                                    <div className='symbol symbol-50px me-5'>
                                        <img src={toAbsoluteUrl("/media/svg/brand-logos/" + idx(platformsConstant, _ => _[item?.platform].icon))} alt='logo' />
                                    </div>
                                    <div className='flex-grow-1'>
                                        <div className=' fw-bold fs-6'>
                                            {item?.platformName}
                                        </div>
                                        <span className='d-block fw-semibold'>{item?.id}</span>
                                    </div>
                                    {item?.health === "OK" ? <SuccessIcon color={`${item.id === values?.chooseSource?.id ? "white" : "success"}`} /> : <ErrorIcon onClick={() => navigate(`/platform/view?platform=${item?.platform}&platform_id=${item?.id}`)} />}

                                </button>
                            </>)
                        })}
                        {!platformData?.filter((val: any) => val.platform === values?.platformSelect).length && <><div className='text-center h-100'>
                            {values?.platformSelect ?
                                <div className='d-flex h-100 align-items-center justify-content-center'>
                                    <Button className='mt-5' onClick={() => navigate('/platform/add')}>Add Platform</Button>
                                </div> :
                                "Please select platform"}
                        </div></>}
                    </div>
                    {errors?.chooseSource?.platformName &&
                        <div className='text-danger mt-2'>
                            {errors?.chooseSource?.platformName}
                        </div>
                    }
                </div>
            </div >
        </>
    )
}
