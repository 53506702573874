import { useSelector } from 'react-redux'
import PlatformTile from '../../../../_metronic/helpers/components/PlatformTile'
import idx from 'idx'
import { jobStatus, platformsConstant } from '../../../../_metronic/common/constants'
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import cronstrue from 'cronstrue'
import { KTIcon } from '../../../../_metronic/helpers'
import { useRef, useState } from 'react'
import { deleteJob } from '../../../redux/Functions/JobsFunctions'
import { DayJS, errorToast, successToast } from '../../../../_metronic/helpers/Utils'
import { Cron as Croner } from 'croner'
import BootstrapTable from 'react-bootstrap-table-next'

export default function JobView_Overview() {
  const navigate = useNavigate()
  const target = useRef<any>(null)
  const { jobDataById: data, isLoading } = useSelector((state: any) => state.jobData)
  const [confirmCheck, setConfirmCheck] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const job_id: any = new URLSearchParams(window.location.search).get('job_id')

  const columns = [
    {
      dataField: 'cell',
      text: 'Upcoming cycles',
      headerClasses: 'text-center',
      formatter: (cell: any, row: any, index: number) => {
        return (
          <>
            {DayJS(row)
              .tz(data?.timeZone || undefined)
              .format('dddd DD MMM YYYY hh:mm:ss A')}{' '}
            ({DayJS().to(DayJS(row).tz(data?.timeZone || undefined))})
          </>
        )
      },
    },
  ]

  const handleDeleteJob = () => {
    setDeleteLoading(true)
    if (!job_id) {
      errorToast('Backup ID is not found')
      navigate('/job/list')
    }
    deleteJob(job_id)
      .then((res: any) => {
        successToast(res?.message)
        navigate('/job/list')
      })
      .catch((err: any) => {
        errorToast(err?.message)
        setDeleteLoading(false)
      })
  }

  const handleEditJob = () => {
    if (data?.status === jobStatus.paymentRequired) {
      return
    }
    navigate('/job/edit?job_id=' + data?.id)
  }

  return (
    <>
      <div className='card'>
        <div className='card-header cursor-pointer align-items-center'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Backup Information</h3>
          </div>
          <div>
            <span className='d-inline-block'>
              <OverlayTrigger
                placement='bottom'
                trigger={data?.status === jobStatus.paymentRequired ? 'hover' : 'click'}
                overlay={
                  <Tooltip id='popover-basic'>
                    Please complete the payment before editing the backup
                  </Tooltip>
                }
                target={target.current}
              >
                <Button
                  style={{
                    backgroundColor:
                      data?.status === jobStatus.paymentRequired ? '#59A9FF' : '#007BFF',
                  }}
                  ref={target}
                  onClick={handleEditJob}
                  disabled={!data?.id}
                >
                  Edit Backup
                </Button>
              </OverlayTrigger>
            </span>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>ID</label>
            <div className='col-lg-9'>
              <span className='fw-bold fs-6 text-dark'>
                {isLoading ? <LoaderSpinner /> : data?.id}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>Source Platform</label>
            <div className='col-lg-9 fv-row'>
              <span className='fw-bold fs-6'>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <PlatformTile
                    icon={idx(platformsConstant, (_) => _[data?.sourcePlatform?.platform].icon)}
                    title={data?.sourcePlatform?.platformName}
                    subTitle={data?.sourcePlatform?.id}
                    health={data?.sourcePlatform?.health}
                    onClick={() =>
                      window.open(
                        `/platform/view?platform=${data?.sourcePlatform?.platform}&platform_id=${data?.sourcePlatform?.id}`,
                        '_blank'
                      )
                    }
                  />
                )}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>Storage Platform</label>
            <div className='col-lg-9 fv-row'>
              <span className='fw-bold fs-6'>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <PlatformTile
                    icon={idx(platformsConstant, (_) => _[data?.targetPlatform?.platform].icon)}
                    title={data?.targetPlatform?.platformName}
                    subTitle={data?.targetPlatform?.id}
                    health={data?.targetPlatform?.health}
                    onClick={() =>
                      window.open(
                        `/platform/view?platform=${data?.targetPlatform?.platform}&platform_id=${data?.targetPlatform?.id}`,
                        '_blank'
                      )
                    }
                  />
                )}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>Time Zone</label>
            <div className='col-lg-9'>
              <span className='fw-bold fs-6 text-dark'>
                {isLoading ? <LoaderSpinner /> : data?.timeZone}
              </span>
            </div>
          </div>
          <div className='row'>
            <label className='col-lg-3 fw-bold text-muted'>Schedule</label>
            <div className='col-lg-9'>
              <span className='fw-bold fs-6 text-dark'>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <>
                    {cronstrue.toString(data?.schedule || '* * * * *')}
                    <div className='w-400px rounded-3 overflow-hidden mt-3'>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-vertical-center overflow-hidden'
                        bootstrap4
                        keyField='id'
                        data={
                          Croner(data?.schedule || '* * * * *', {
                            timezone: data?.timeZone || undefined,
                          }).nextRuns(5) || []
                        }
                        columns={columns}
                        noDataIndication={() => 'No Records Found'}
                        rowClasses={'border-bottom'}
                      />
                    </div>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-8'>
        <div className='card-body border-top p-9'>
          <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6'>
            <KTIcon iconName='information-5' className='fs-2tx text-danger me-4' />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Deleting Backup Alert</h4>
                <div className='fs-6 text-gray-600'>
                  Please be advised that effective immediately, all funds associated with your
                  current subscription will be cancelled, and no refunds will be issued for the
                  remaining duration of your subscription term. Additionally, future runs will be
                  cancelled. It is important to note that once this action is taken, it cannot be
                  reverted. We appreciate your understanding in this matter.
                </div>
              </div>
            </div>
          </div>

          <div className='form-check form-check-solid fv-row'>
            <input
              id='deactivate'
              className='form-check-input'
              type='checkbox'
              role='button'
              checked={confirmCheck}
              onChange={({ target }: any) => setConfirmCheck(target.checked)}
            />
            <label
              className='form-check-label fw-bold ps-2 fs-6 cursor-pointer'
              htmlFor='deactivate'
            >
              I confirm this backup deletion
            </label>
          </div>
          <div className='text-end'>
            <button
              id='kt_account_deactivate_account_submit'
              type='submit'
              className='btn btn-danger fw-bold'
              disabled={!confirmCheck || deleteLoading}
              onClick={handleDeleteJob}
            >
              {deleteLoading ? <LoaderSpinner text='Please wait...' /> : 'Delete Backup'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
