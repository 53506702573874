import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { LoadStripeFunction } from '../../../redux/Functions/CommonFunctions'
import PaymentCheckoutAuth from './PaymentCheckoutAuth'
import { useNavigate } from 'react-router-dom'
import { errorToast } from '../../../../_metronic/helpers/Utils'

export default function AuthPayment() {
    const loadStripePromise = LoadStripeFunction()
    const navigate = useNavigate();
    const [localDataState, setLocalDataState] = useState<any>({})
    let localStorageAuth2: any = localStorage.getItem("backupSuitAuth2")

    useEffect(() => {
        setLocalDataState(JSON.parse(localStorageAuth2 || {}))
        if (localStorageAuth2 && !JSON.parse(localStorageAuth2)?.pending_payment?.clientSecret) {
            errorToast("Payment not allowed")
            return navigate("/auth/login")
        }
    }, [])

    return (
        <>
            {localDataState?.pending_payment?.clientSecret &&
                <Elements stripe={loadStripePromise} options={{ clientSecret: localDataState?.pending_payment?.clientSecret }}>
                    <PaymentCheckoutAuth clientSecret={localDataState?.pending_payment?.clientSecret} tempData={localDataState} />
                </Elements>}
        </>
    )
}
