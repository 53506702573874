import { PageTitle } from '../../../_metronic/layout/core'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import JobList from './components/JobList'
import JobAdd from './components/JobAdd';
import JobView from './components/JobView';
import JobView_Job_History_view from './components/JobView_Job_History_view';
import { useEffect } from 'react';

const jobBreadCrumbs = [
    {
        title: 'Backups',
        path: '/job/list',
        isSeparator: false,
        isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];

export default function JobPage() {
    const location = useLocation();
    let job_id = new URLSearchParams(window.location.search).get('job_id');
    useEffect(() => { job_id = new URLSearchParams(window.location.search).get('job_id') }, [location])
    return (
        <>
            <Routes>
                <Route
                    path='list'
                    element={
                        <>
                            <PageTitle breadcrumbs={[]} >Backup List</PageTitle>
                            <JobList />
                        </>
                    }
                />
                <Route
                    path='view'
                    element={
                        <>
                            <PageTitle breadcrumbs={jobBreadCrumbs} >View Backup</PageTitle>
                            <JobView />
                        </>
                    }
                />
                <Route
                    path='view-job-history'
                    element={
                        <>
                            <PageTitle breadcrumbs={[
                                {
                                    title: 'Backups',
                                    path: '/job/list',
                                    isSeparator: false,
                                    isActive: true,
                                },
                                {
                                    title: '',
                                    path: '',
                                    isSeparator: true,
                                    isActive: true,
                                },
                                {
                                    title: 'Backup View',
                                    path: '/job/view?tabStep=Overview&job_id=' + job_id,
                                    isSeparator: false,
                                    isActive: true,
                                },
                                {
                                    title: '',
                                    path: '',
                                    isSeparator: true,
                                    isActive: false,
                                },
                            ]} >View Backup History</PageTitle>
                            <JobView_Job_History_view />
                        </>
                    }
                />
                <Route
                    path='add'
                    element={
                        <>
                            <PageTitle breadcrumbs={jobBreadCrumbs}>Add Backup</PageTitle>
                            <JobAdd />
                        </>
                    }
                />
                <Route
                    path='edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={jobBreadCrumbs}>Edit Backup</PageTitle>
                            <JobAdd />
                        </>
                    }
                />
                <Route index element={<Navigate to='/job/list' />} />
            </Routes>
        </>
    )
}
