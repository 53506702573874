import { useState } from 'react';
import { Button } from 'react-bootstrap';
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useSelector } from 'react-redux';
import AwsServerAddModal from './AwsServerAddModal';

export default function AWSPlatformOverviewAccountInfo() {
    const { credentials, platformName, id, health } = useSelector((state: any) => state.platformData.platformsDataById)
    const { isLoading } = useSelector((state: any) => state.platformData);
    const [awsAddModal, setAwsAddModal] = useState(false);
    const [AwsModalData, setAwsModalData] = useState<any>({})
    const [passwordFlags, setPasswordFlags] = useState<any>({
        isPasswordShow: false,
    })

    const changePasswordFlag = (flag: string) => setPasswordFlags({ ...passwordFlags, [flag]: !passwordFlags?.[flag] })

    const handleEditConfiguration = () => {
        setAwsModalData({
            id,
            platformName: platformName,
            access_key_id: credentials?.access_key_id,
            secret_key_id: credentials?.secret_key_id,
            region: credentials?.region,
            bucket_name: credentials?.bucket_name,
            path: credentials?.path,
            endpoint: credentials?.endpoint,
        })
        setAwsAddModal(true)
    }

    return (<>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer align-items-center'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Server Configuration</h3>
                </div>

                <Button onClick={handleEditConfiguration} disabled={isLoading} >
                    Edit Configuration
                </Button>
            </div>

            <div className='card-body px-9 py-5'>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Access Key ID</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{isLoading ? <LoaderSpinner /> : credentials?.access_key_id || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Secret Key ID</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                            {isLoading ? <LoaderSpinner /> : passwordFlags?.isPasswordShow ? credentials?.secret_key_id : credentials?.secret_key_id ? "********************" : "-"}
                            {credentials?.secret_key_id && <span role='button' onClick={() => changePasswordFlag("isPasswordShow")} ><KTIcon iconName={passwordFlags?.isPasswordShow ? "eye-slash" : "eye"} iconType='solid' className='ms-1 fs-4 text-dark' /></span>}
                        </span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Region</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{isLoading ? <LoaderSpinner /> : credentials?.region || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Bucket Name</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.bucket_name || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Path</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.path || "-"}</span>
                    </div>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-3 fw-bold text-muted'>Endpoint</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : credentials?.endpoint || "-"}</span>
                    </div>
                </div>
                <div className='row'>
                    <label className='col-lg-3 fw-bold text-muted'>Authorization Health</label>
                    <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{isLoading ? <LoaderSpinner /> : health}</span>
                    </div>
                </div>
            </div>
        </div>
        <AwsServerAddModal awsAddModal={awsAddModal} setAwsAddModal={setAwsAddModal} initData={AwsModalData} isEdit={true} />
    </>)
}
