import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Button } from 'react-bootstrap'
import { ApiGet, errorToast } from '../../../../_metronic/helpers/Utils'
import { useState } from 'react'
import { platformsConstant } from '../../../../_metronic/common/constants'
import SftpServerAddModal from './sftpPlatform/SftpServerAddModal'
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner'
import AwsServerAddModal from './AwsPlatform/AwsServerAddModal'


export default function PlatformAdd() {
    const [loading, setLoading] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState<any>("");
    const [sftpAddModal, setSftpAddModal] = useState(false)
    const [awsAddModal, setAwsAddModal] = useState(false)

    const zohoAuthorization = (platform: string) => {
        setLoading(true);

        ApiGet(`/platform/${platform}/generate-auth-url`)
            .then((response: any) => {
                if (response?.data?.data) {
                    window.location.href = response.data.data;
                }
            })
            .catch((err: any) => {
                errorToast(err?.message)
                setLoading(false);
            })
    }

    const handleAuthorizeBtn = () => {
        switch (selectedPlatform) {
            case "zoho":
                zohoAuthorization("zoho")
                break;
            case "Zoho WorkDrive":
                zohoAuthorization("zoho-WorkDrive")
                break;
            case "sftp":
                setSftpAddModal(true);
                break;
            case "AWS S3":
                setAwsAddModal(true);
                break;
        }
    }
    return (
        <>
            <div className="d-flex justify-content-between gap-7 w-100">
                <div className="sourceDiv w-100">
                    <h1 className='text-center mb-7'>CRM Platforms</h1>
                    <div className="d-flex flex-wrap justify-content-center align-content-start gap-7 w-100 overflow-auto" >
                        {Object.values(platformsConstant).filter((v: any) => v?.isSource).map((item: any, index: number) => {
                            return (<>
                                <div
                                    className={`w-200px h-125px border border-2 ${item?.name === selectedPlatform ? "border-primary shadow-inner" : "border-secondary"} rounded-4 text-center p-5 cursor-pointer`}
                                    onClick={() => setSelectedPlatform(item?.name)}
                                    key={index}
                                >
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/brand-logos/" + item?.icon)}
                                        width="auto"
                                        height={55}
                                        title={item?.name}
                                    />
                                    <h4 className={`mb-0 mt-5 ${item?.name === selectedPlatform ? "fw-bolder" : ""}`}>{item?.displayName}</h4>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                <hr className='border border-primary m-0' />
                <div className="sourceDiv w-100">
                    <h1 className='text-center mb-7'>Storage Platforms</h1>
                    <div className="d-flex flex-wrap justify-content-center align-content-start gap-7 w-100 overflow-auto" >
                        {Object.values(platformsConstant).filter((v: any) => !v?.isSource).map((item: any, index: number) => {
                            const isActive = item.name === selectedPlatform;
                            return (<>
                                <div
                                    className={`w-200px h-125px border border-2 ${isActive ? "border-primary shadow-inner" : "border-secondary"} rounded-4 text-center p-5 cursor-pointer`}
                                    onClick={() => setSelectedPlatform(item.name)}
                                    key={index}
                                >
                                    <img
                                        src={toAbsoluteUrl("/media/svg/brand-logos/" + item.icon)}
                                        width="auto"
                                        height={55}
                                        title={item.name}
                                    />
                                    <h4 className={`mb-0 mt-5 ${isActive ? "fw-bolder" : ""}`}>{item.displayName}</h4>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
            </div>
            <div className="text-center mt-15">
                <Button onClick={handleAuthorizeBtn} disabled={loading || !selectedPlatform} >
                    {loading ?
                        <LoaderSpinner text='Please wait...' />
                        :
                        "Authorize"
                    }
                </Button>
            </div>
            <SftpServerAddModal sftpAddModal={sftpAddModal} setSftpAddModal={setSftpAddModal} />
            <AwsServerAddModal awsAddModal={awsAddModal} setAwsAddModal={setAwsAddModal} />
        </>
    )
}
