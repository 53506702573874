import { ErrorMessage, Field } from 'formik'
import React from 'react'

export default function JobAddStep1() {
    return (
        <div className='mb-5 fv-row w-100'>
            <label className='mb-2'>Backup Name</label>
            <Field
                type='text'
                className='form-control form-control-lg'
                name='jobName'
                autoComplete="off"
                autoCorrect="off"
                placeholder="Enter Backup Name"
            />
            <div className='text-danger mt-2'>
                <ErrorMessage name='jobName' />
            </div>
        </div>
    )
}
