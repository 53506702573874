import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from '../Functions/CommonFunctions'

const initialState = {
    step: "Overview",
    jobIdForPaymentSuccess: "",
    jobDataById: {},
    isLoading: false,
    jobSuitableProductData: {},
}

export const jobSlice = createSlice({
    name: 'jobData',
    initialState,
    reducers: {
        changeJobStep: (state, { payload }) => {
            state.step = payload
        },
        changeJobDataById: (state, { payload }) => {
            state.jobDataById = payload
        },
        changeJobIsLoading: (state, { payload }) => {
            state.isLoading = payload
        },
        changeJobIdForPaymentSuccess: (state, { payload }) => {
            state.jobIdForPaymentSuccess = payload
        },
        changeJobSuitableProductData: (state, { payload }) => {
            state.jobSuitableProductData = payload
        },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const { changeJobStep, changeJobDataById, changeJobIsLoading, changeJobIdForPaymentSuccess, changeJobSuitableProductData } = jobSlice.actions

export default jobSlice.reducer