import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { FaEye, FaPlus } from 'react-icons/fa'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import { getPlatforms } from '../../../redux/Functions/PlatformFunctions'
import { DayJS, errorToast } from '../../../../_metronic/helpers/Utils'
import SuccessIcon from '../../../../_metronic/helpers/components/SuccessIcon'
import ErrorIcon from '../../../../_metronic/helpers/components/ErrorIcon'
import { platformsConstant } from '../../../../_metronic/common/constants'
import idx from 'idx'
import TableLoader from '../../../../_metronic/helpers/components/TableLoader'

export default function PlatformList() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      dataField: 'platform',
      text: 'Platform',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-50px shadow-sm me-5'>
                <img
                  src={toAbsoluteUrl(
                    '/media/svg/brand-logos/' + idx(platformsConstant, (_) => _[cell].icon)
                  )}
                  className=''
                  alt='logo'
                />
              </div>
              <div className=''>
                <Link
                  rel='noopener noreferrer'
                  to={`/platform/view?platform=${cell}&platform_id=${row?.id}`}
                  className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'
                >
                  {row?.platformName || ''}
                </Link>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {row?.platformUniqueIdentifier || ''}
                </span>
              </div>
            </div>
          </>
        )
      },
    },
    {
      dataField: 'platform',
      text: 'Type',
      formatter: (cell: any, row: any) => {
        return <>{idx(platformsConstant, (_) => _[cell].displayName) || '-'}</>
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Modified',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              {DayJS(cell).format('DD/MM/YYYY hh:mm A')}
            </div>
          </>
        )
      },
    },
    {
      dataField: 'health',
      text: 'Health',
      formatter: (cell: any, row: any) => {
        if (cell === "OK") {
          return <div className='badge badge-light-success badge-lg fw-bold'>
            {cell}
          </div>
        } else {
          return <div className='badge badge-light-danger badge-lg fw-bold'>
            {cell}
          </div>
        }

      },
    },
    {
      dataField: 'isActive',
      text: 'Active',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              {cell ? <SuccessIcon /> : <ErrorIcon />}
            </div>
          </>
        )
      },
    },
    {
      dataField: 'Action',
      text: 'Action',
      style: { width: '10px' },
      formatter: (cell: any, row: any) => {
        return (
          <>
            <Link
              to={`/platform/view?platform=${row?.platform}&platform_id=${row?.id}`}
              title='View Platform'
              className='btn btn-icon btn-light-primary btn-sm'
            >
              <span className='svg-icon svg-icon-md svg-icon-primary'>
                <FaEye />
              </span>
            </Link>
          </>
        )
      },
    },
  ]

  const fetchData = () => {
    setLoading(true)
    getPlatforms()
      .then((res: any) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false)
        errorToast(err?.message)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='card p-8'>
        <div className='d-flex justify-content-end mb-5'>
          {/* <input className='form-control w-350px' placeholder='Search...' /> */}

          <Link to='/platform/add'>
            <Button className='btn-light-primary'>
              <FaPlus /> Add Platform
            </Button>
          </Link>
        </div>
        <BootstrapTable
          wrapperClasses='table-responsive'
          bordered={false}
          classes='table table-vertical-center overflow-hidden'
          bootstrap4
          keyField='id'
          data={data || []}
          columns={columns}
          noDataIndication={() => (loading ? <TableLoader /> : 'No Records Found')}
          rowClasses={'border-bottom'}
        />
      </div>
    </>
  )
}
