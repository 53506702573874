import clsx from "clsx"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from 'yup'
import { AUTH_LOCAL_STORAGE_KEY } from "../core/AuthHelpers"
import { useAuth } from "../core/Auth"
import { ApiPost, successToast } from "../../../../_metronic/helpers/Utils"

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    otp: Yup.string()
        .typeError("Please enter number format only")
        .min(6, 'Minimum 6 symbols')
        .max(6, 'Maximum 6 symbols')
        .required('OTP is required'),
})

export default function OTPVerification() {
    const navigate = useNavigate();
    const localStorageData: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    const parsedLocalStorageData: any = JSON.parse(localStorageData)
    const [loading, setLoading] = useState(false)
    const { setCurrentUser } = useAuth()

    const formik = useFormik({
        initialValues: {
            email: parsedLocalStorageData?.email,
            otp: ""
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setStatus("")
            ApiPost("/account/verify-email", { otp: values.otp })
                .then(() => {
                    successToast("OTP verified successfully")
                    setCurrentUser(parsedLocalStorageData)
                }).catch((error: any) => {
                    setStatus(error?.response?.data?.message);
                    setLoading(false)
                    setSubmitting(false)
                })
        },
    })

    const onResendOTP = async () => {
        setLoading(true)
        ApiPost("/account/resend-verification-otp", {})
            .then(() => { setLoading(false) })
            .catch((error: any) => { setLoading(false) })
    }
    useEffect(() => {
        if (!parsedLocalStorageData?.authorization) {
            navigate("/auth/login")
        }
    }, [localStorage])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_email_verify_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Email Verification</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    OTP received in your registered email account
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {formik?.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text text-center font-weight-bold'>
                        {formik?.status}
                    </div>
                </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    disabled
                />

            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
                <input
                    type='text'
                    placeholder=''
                    autoComplete='off'
                    onKeyUp={(e: any) => {
                        if (formik.values.otp.length > 5 && e.keyCode !== 13) {
                            formik.handleSubmit();
                        }
                    }}
                    {...formik.getFieldProps('otp')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.otp && formik.errors.otp },
                        {
                            'is-valid': formik.touched.otp && !formik.errors.otp,
                        }
                    )}
                />
                {formik.touched.otp && formik.errors.otp && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.otp}</span>
                        </div>
                    </div>
                )}
                <div role="button" className="text-end text-hover-primary" onClick={onResendOTP}>Resend OTP</div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' className='btn btn-primary me-4' disabled={loading}>

                    {loading ? (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (<span className='indicator-label'>Submit</span>)}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
