import ZohoPlatformOverview from './zohoPlatform/ZohoPlatformOverview';
import SftpPlatformOverview from './sftpPlatform/SftpPlatformOverview';
import AWSPlatformOverview from './AwsPlatform/AWSPlatformOverview';
import ZohoWorkDrivePlatformOverview from './zohoWorkDrivePlatform/ZohoWorkDrivePlatformOverview';
import { platformNames } from '../../../../_metronic/common/constants';

export default function PlatformOverview() {
    const platformQ = new URLSearchParams(window.location.search).get('platform');
    return (<>
        {platformQ === platformNames.zoho && <ZohoPlatformOverview />}
        {platformQ === platformNames.sftp && <SftpPlatformOverview />}
        {platformQ === platformNames.awsS3 && <AWSPlatformOverview />}
        {platformQ === platformNames.zohoWorkDrive && <ZohoWorkDrivePlatformOverview />}
    </>)
}
