import { Navigate, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import BillingList from "./components/BillingList";

export default function BillingPage() {
    return (
        <Routes>
            <Route
                path='list'
                element={
                    <>
                        <PageTitle breadcrumbs={[]} >Billing</PageTitle>
                        <BillingList />
                    </>
                }
            />
            <Route index element={<Navigate to='/billing/list' />} />
        </Routes>
    )
}
