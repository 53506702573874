import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react'
import { Button } from 'react-bootstrap';
import LoaderSpinner from '../../../../_metronic/helpers/components/LoaderSpinner';
import { ApiPost, errorToast, successToast } from '../../../../_metronic/helpers/Utils';
import { useNavigate } from 'react-router-dom';

export default function PaymentCheckoutAuth({ clientSecret, tempData }: any) {
    const stripe: any = useStripe();
    const elements: any = useElements();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoading(true);

        if (elements === null) return;

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();

        if (submitError) setLoading(false);

        const response = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            redirect: 'if_required',
            error: (error: any) => {
                errorToast(error?.message)
                console.log('error', error)
            }
        });
        if (response?.paymentIntent?.status === "succeeded") {
            successToast("Card Successfully Added");
            if (!tempData?.emailVerified) {
                if (tempData) {
                    await localStorage.setItem("backupSuitAuth", JSON.stringify(tempData));
                    navigate("/auth/otp-verification");
                } else {
                    navigate('/auth/login')
                }
            }
        }
        console.log('response', response)
    }

    return (<>
        <form>
            <PaymentElement />
            <Button type='submit' className='mt-5' onClick={handleSubmit} disabled={loading} >{loading ? <LoaderSpinner text='Please wait...' /> : "Submit"}</Button>
        </form>
    </>)
}
