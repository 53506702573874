import { ApiGet, ApiPatch, ApiPost } from "../../../_metronic/helpers/Utils"

export const createSubscription = (body: object) => {
    return ApiPost("/subscription/create-subscription", body)
}

export const getSubscription = (params?: object) => {
    return ApiGet("/subscription/get-subscription", params)
}

export const cancelSubscription = (subscriptionId: string) => {
    return ApiPatch("/subscription/cancel-subscription/" + subscriptionId, {})
}

export const pauseResumeSubscription = (subscriptionId: string, body: object) => {
    return ApiPatch("/subscription/pause-subscription/" + subscriptionId, body)
}

export const getSubscriptionPlans = () => {
    return ApiGet("/subscription/get-plans")
}

export const getSuitableProduct = (body: object) => {
    return ApiPost("/subscription/get-suitable-product", body)
}

export const getRequiredCreditPayment = (id: string) => {
    return ApiGet("/subscription/required-credit-payment/" + id)
}

export const getStripePublishableKey = () => {
    return ApiGet("/stripe/get-publishable-key", {}, false)
}

export const getTransaction = (params: object) => {
    return ApiGet("/transaction/get-transactions", params)
}

export const getTransactionInvoice = (params: object, token?: string | undefined) => {
    return ApiGet("/transaction/get-invoice", params, true, token)
}