import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg9-dark.jpg')})` }}>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-white mb-4'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-5 text-white mb-7'>We can't find that page.</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/dashboard' className='btn btn-info'>
          Return Home
        </Link>
      </div>
      {/* end::Link */}
    </div>
  )
}

export { Error404 }
