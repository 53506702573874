import { useSelector } from "react-redux";
import PlatformViewHeader from "./PlatformViewHeader";
import PlatformOverview from "./PlatformOverview";

export default function PlatformViewDetails() {
    const { step } = useSelector((state: any) => state.platformData);

    return (
        <>
            <PlatformViewHeader />
            {step === "overview" && <PlatformOverview />}
        </>
    )
}
