import {
    Elements,
} from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { LoadStripeFunction } from '../../../../redux/Functions/CommonFunctions';

export default function JobAddStep6Payment({ values }: any) {
    const loadStripePromise = LoadStripeFunction()

    return (
        <div className='w-100' >
            {values?.clientSecret &&
                <Elements stripe={loadStripePromise} options={{ clientSecret: values?.clientSecret, }}   >
                    <StripeCheckoutForm values={values} />
                </Elements>}

        </div>
    )
}
