import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react'
import { Button } from 'react-bootstrap';
import LoaderSpinner from '../../../../../_metronic/helpers/components/LoaderSpinner';
import { useSelector } from 'react-redux';

export default function StripeCheckoutForm({ values }: any) {
    const stripe: any = useStripe();
    const elements: any = useElements();

    const { jobIdForPaymentSuccess } = useSelector((state: any) => state.jobData)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoading(true);

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            return setLoading(false);
        }

        await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret: values.clientSecret,
            confirmParams: {
                return_url: window.location.origin + "/job/view?tabStep=Overview&job_id=" + jobIdForPaymentSuccess,
            },
            error: (error: any) => {
            }
        });
    }

    return (<>
        <form>
            <PaymentElement />
            <Button type='submit' className='mt-5' onClick={handleSubmit} disabled={loading} >{loading ? <LoaderSpinner text='Please wait...' /> : "Submit"}</Button>
        </form>
    </>)
}
