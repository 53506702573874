import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { FaEye, FaPlus } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { DayJS, errorToast } from '../../../../_metronic/helpers/Utils'
import TableLoader from '../../../../_metronic/helpers/components/TableLoader'
import { getJobs } from '../../../redux/Functions/JobsFunctions'
import { Cron as Croner } from 'croner'
import cronstrue from 'cronstrue'
import { jobStatus } from '../../../../_metronic/common/constants'
import { KTIcon } from '../../../../_metronic/helpers'

export default function JobList() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      dataField: 'no',
      text: 'No',
      formatter: (cell: any, row: any, index: number) => {
        return (
          <>
            <div className='d-flex align-items-center'>{index + 1}</div>
          </>
        )
      },
    },
    {
      dataField: 'name',
      text: 'Backup Name',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>{cell}</div>
          </>
        )
      },
    },
    {
      dataField: 'schedule',
      text: 'Schedule',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              {cronstrue.toString(cell || '* * * * *')}
            </div>
          </>
        )
      },
    },
    {
      dataField: 'schedule',
      text: 'Next Run',
      formatter: (cell: any, row: any) => {
        const nextRun = Croner(cell || '* * * * *', {
          timezone: row?.timeZone || undefined,
        }).nextRun()
        return (
          <>
            {DayJS(nextRun)
              .tz(row?.timeZone || undefined)
              .format('dddd DD MMM YYYY hh:mm:ss A')}{' '}
            ({DayJS().to(DayJS(nextRun).tz(row?.timeZone || undefined))})
          </>
        )
      },
    },
    {
      dataField: 'timeZone',
      text: 'Time Zone',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>{cell}</div>
          </>
        )
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              {cell === jobStatus.active && (
                <div className='bg-light-success text-success rounded py-1 px-3 d-inline-flex justify-content-center align-items-center'>
                  <KTIcon iconName='check' className='fs-3 text-success me-1' />
                  Active
                </div>
              )}

              {cell === jobStatus.inactive && (
                <div className='bg-light-danger text-danger rounded py-1 px-3 d-inline-flex justify-content-center align-items-center'>
                  <KTIcon iconName='cross' iconType='solid' className='fs-3 text-danger me-1' />
                  Inactive
                </div>
              )}

              {cell === jobStatus.pause && (
                <div className='bg-light-warning text-warning rounded py-1 px-3 d-inline-flex justify-content-center align-items-center'>
                  <KTIcon iconName='row-vertical' iconType='solid' className=' text-warning me-1' />
                  Pause
                </div>
              )}

              {cell === jobStatus.paymentRequired && (
                <div className='bg-light-warning text-warning rounded py-1 px-3 d-inline-flex justify-content-center align-items-center text-nowrap'>
                  <KTIcon iconName='two-credit-cart' className='fs-3 text-warning me-1' />
                  Payment Required
                </div>
              )}
            </div>
          </>
        )
      },
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerClasses: 'text-end',
      style: { width: '10px' },
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex gap-3'>
              <Link
                to={`/job/view?tabStep=Overview&job_id=${row?.id}`}
                title='View Backup'
                className='btn btn-icon btn-secondary btn-sm'
              >
                <span className='svg-icon svg-icon-md svg-icon-secondary'>
                  <FaEye />
                </span>
              </Link>
            </div>
          </>
        )
      },
    },
  ]

  const fetchData = () => {
    setLoading(true)
    getJobs()
      .then((res: any) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false)
        errorToast(err?.message)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='card p-8'>
        <div className='d-flex justify-content-end mb-5'>
          {/* <input className='form-control w-350px' placeholder='Search...' /> */}

          <Link to='/job/add'>
            <Button className='btn-light-primary'>
              <FaPlus /> Add Backup
            </Button>
          </Link>
        </div>
        <BootstrapTable
          wrapperClasses='table-responsive'
          bordered={false}
          classes='table table-vertical-center overflow-hidden'
          bootstrap4
          keyField='id'
          data={data || []}
          columns={columns}
          noDataIndication={() => (loading ? <TableLoader /> : 'No Records Found')}
          rowClasses={'border-bottom'}
        />
      </div>
    </>
  )
}
