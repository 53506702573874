import axios from 'axios'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Cron as Croner } from 'croner'
import Lottie from 'react-lottie-player'
export const BaseURL = process.env.REACT_APP_API_URL
// export const BaseURL = "http://192.168.0.103:8080/api/v1";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
export const DayJS = dayjs

const getAuthData = () => {
  const localData: any = localStorage.getItem('backupSuitAuth')
  return JSON.parse(localData)
}
export function successToast(text: string) {
  toast.success(text || 'Something went wrong')
}
export function errorToast(text: string) {
  toast.error(text || 'Something went wrong')
}

export function StatusBadges({ type }: { type: string }) {
  switch (type) {
    case 'pending':
      return <span className='badge badge-light-warning badge-lg'>Pending</span>
    case 'success':
      return <span className='badge badge-light-success badge-lg'>Success</span>
    case 'failed':
      return <span className='badge badge-light-danger badge-lg'>Failed</span>
    case 'running':
      return <span className='badge badge-light-info badge-lg'>Running</span>
    default:
      return <span>-</span>
  }
}

interface CronJobCounterProps {
  cronString: string
  startDate?: Date
  endDate: Date
}

export const CronJobCounter = ({ cronString, startDate, endDate }: CronJobCounterProps) => {
  const dayCount = DayJS(endDate).diff(startDate, 'day')
  const nextRuns = Croner(cronString, { startAt: startDate, stopAt: endDate }).nextRuns(dayCount)
  return nextRuns.length
}

export const fileUpload = (e: any, directory: string) => {
  let file = e.target.files[0]
  if (!file) {
    return null
  }
  let fileURL = URL.createObjectURL(file)
  file.fileURL = fileURL
  let formData = new FormData()
  formData.append('directory', directory)
  formData.append('files', file)
  return ApiPost('/file-upload', formData)
    .then((res: any) => res?.data?.files_url[0])
    .catch((err) => { })
}

export const ApiGet = (url: string, params?: object, isUserRole = true, token?: string) => {
  const localData = getAuthData()
  let userType: string = ''
  if (isUserRole) {
    switch (localData?.role) {
      case 'client':
        userType = '/user'
        break
      case 'admin':
        userType = '/admin'
        break
      default:
        userType = '/user'
        break
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + userType + url, {
        headers: {
          Authorization: 'Bearer ' + (token || localData?.authorization),
        },
        params,
      })
      .then((responseJson) => {
        resolve(responseJson?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          window.location.reload()
        }
        reject(error?.response?.data || error)
      })
  })
}

export const ApiPost = (url: string, body: any, queryString?: object, isUserRole = true, token?: string) => {
  const localData = getAuthData()
  let userType: string = ''
  if (isUserRole) {
    switch (localData?.role) {
      case 'client':
        userType = '/user'
        break
      case 'admin':
        userType = '/admin'
        break
      default:
        userType = '/user'
        break
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + userType + url, body, {
        headers: {
          Authorization: 'Bearer ' + (token || localData?.authorization),
        },
        params: queryString
      })
      .then((responseJson) => {
        resolve(responseJson?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          window.location.reload()
        }
        reject(error?.response?.data || error)
      })
  })
}

export const ApiPut = (url: string, body: any, queryString?: object, isUserRole = true) => {
  const localData = getAuthData()
  let userType: string = ''
  if (isUserRole) {
    switch (localData?.role) {
      case 'client':
        userType = '/user'
        break
      case 'admin':
        userType = '/admin'
        break
      default:
        userType = '/user'
        break
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + userType + url, body, {
        headers: {
          Authorization: 'Bearer ' + localData?.authorization,
        },
        params: queryString
      })
      .then((responseJson) => {
        resolve(responseJson?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          window.location.reload()
        }
        reject(error?.response?.data || error)
      })
  })
}

export const ApiPatch = (url: string, body: any, queryString?: object, isUserRole = true) => {
  const localData = getAuthData()
  let userType: string = ''
  if (isUserRole) {
    switch (localData?.role) {
      case 'client':
        userType = '/user'
        break
      case 'admin':
        userType = '/admin'
        break
      default:
        userType = '/user'
        break
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + userType + url, body, {
        headers: {
          Authorization: 'Bearer ' + localData?.authorization,
        },
      })
      .then((responseJson) => {
        resolve(responseJson?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          window.location.reload()
        }
        reject(error?.response?.data || error)
      })
  })
}

export const ApiDelete = (url: string, params?: object, isUserRole = true) => {
  const localData = getAuthData()
  let userType: string = ''
  if (isUserRole) {
    switch (localData?.role) {
      case 'client':
        userType = '/user'
        break
      case 'admin':
        userType = '/admin'
        break
      default:
        userType = '/user'
        break
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + userType + url, {
        headers: {
          Authorization: 'Bearer ' + localData?.authorization,
        },
        params
      })
      .then((responseJson) => {
        resolve(responseJson?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          window.location.reload()
        }
        reject(error?.response?.data || error)
      })
  })
}
export const LottieIconViewer = ({ json = {}, height = 30, width = 30 }) => {
  return (
    <Lottie
      loop
      animationData={json}
      play
      style={{ width, height }}
    />
  )
}