import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { forgotPassword, resetPassword } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { errorToast, successToast } from '../../../../_metronic/helpers/Utils'
// @ts-ignore
import OtpTimer from 'otp-timer'

const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    otp: Yup.string()
        .typeError("Please enter number format only")
        .min(6, 'Minimum 6 symbols')
        .max(6, 'Maximum 6 symbols')
        .required('OTP is required'),
    newPassword: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Confirm Password is required')
        .oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const authEmail = localStorage.getItem('authEmail');

    const formik = useFormik({
        initialValues: {
            email: authEmail || "",
            otp: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setStatus("")
            setTimeout(() => {
                resetPassword(values.email, values.otp, values.newPassword)
                    .then(({ data: { result } }) => {
                        setLoading(false);
                        localStorage.removeItem('authEmail')
                        successToast("Password has been changed successfully.")
                        navigate("/auth/login")
                    })
                    .catch((error: any) => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(error?.response?.data?.message || 'Something went wrong')
                    })
            }, 1000)
        },
    });

    const handleResendOtpClick = async () => {
        setLoading(true);
        await forgotPassword(authEmail || "").then(() => {
            successToast("OTP resent Successfully");
            setLoading(false);
        }).catch(() => {
            errorToast("Resent OTP Failed")
            setLoading(false);
        });
    }

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    OTP sent to {authEmail}.
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {formik?.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold text-center'>
                        {formik?.status}
                    </div>
                </div>
            )}

            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    disabled
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
                <input
                    type='text'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('otp')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.otp && formik.errors.otp },
                        {
                            'is-valid': formik.touched.otp && !formik.errors.otp,
                        }
                    )}
                />
                {formik.touched.otp && formik.errors.otp && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.otp}</span>
                        </div>
                    </div>
                )}
                <div className="text-end">
                    <OtpTimer seconds={30} minutes={0} resend={handleResendOtpClick} text="Resend in " ButtonText="Resend OTP" background="transparent" buttonColor="#009ef7" textColor="#000000" />
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3' data-kt-password-meter='true'>
                <label className='form-label fw-bolder text-dark fs-6 mb-1'>Password</label>
                <input
                    type='password'
                    placeholder='New Password'
                    autoComplete='off'
                    {...formik.getFieldProps('newPassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                        },
                        {
                            'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                        }
                    )}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.newPassword}</span>
                        </div>
                    </div>
                )}
                {/* begin::Meter */}
                <div
                    className='d-flex align-items-center my-3'
                    data-kt-password-meter-control='highlight'
                >
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
            </div>
            <div className='text-muted mb-3'>
                Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6 mb-1'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Confirm Password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirmPassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                        },
                        {
                            'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                        }
                    )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
                    {loading ? (
                        <span className='indicator-progress' style={{ display: "block" }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (<span className='indicator-label'>Reset Password</span>)}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
