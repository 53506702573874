import { ApiDelete, ApiGet, ApiPost } from "../../../_metronic/helpers/Utils"

export const getPaymentMethods = (params?: object) => {
    return ApiGet("/billing/get-payment-methods", params)
}

export const addDefaultPaymentMethod = (body: object) => {
    return ApiPost("/billing/add-default-payment-method", body)
}

export const updatePaymentMethod = (body: object) => {
    return ApiPost("/billing/update-default-payment-method", body)
}

export const deletePaymentMethod = (ID: string) => {
    return ApiDelete("/billing/detach-payment-method", { stripe_payment_method_id: ID })
}