import { Form, Formik, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { ICreateJob, biWeekDaysArray, createJobSchemas, getLabeledOptions, jobInits, jobStatus } from "../../../../_metronic/common/constants";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import JobAddStep1 from "./jobAddSteps/JobAddStep1";
import JobAddStep2 from "./jobAddSteps/JobAddStep2";
import JobAddStep3 from "./jobAddSteps/JobAddStep3";
import JobAddStep4 from "./jobAddSteps/JobAddStep4";
import JobAddStep5Preview from "./jobAddSteps/JobAddStep5Preview";
import { createJob, getJobs, updateJob } from "../../../redux/Functions/JobsFunctions";
import { DayJS, errorToast, successToast } from "../../../../_metronic/helpers/Utils";
import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../../../../_metronic/helpers/components/LoaderSpinner";
import TableLoader from "../../../../_metronic/helpers/components/TableLoader";
import { getSubscriptionPlans } from "../../../redux/Functions/SubscriptionFuntions";
import { useDispatch, useSelector } from "react-redux";
import JobAddStep6Payment from "./jobAddSteps/JobAddStep6Payment";
import { changeSubscriptionPlans } from "../../../redux/Slices/SubscriptionSlice";
import { changeJobIdForPaymentSuccess } from "../../../redux/Slices/JobSlice";
import { AppDispatch } from "../../../redux/store";
import Cron from "croner";

export default function JobAdd() {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [isSubmitButton, setIsSubmitButton] = useState(false)
    const [currentSchema, setCurrentSchema] = useState(createJobSchemas[0])
    const [initValues, setInitValues] = useState<ICreateJob>(jobInits)
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const isEdit: boolean = window.location.pathname.includes("/edit")
    const job_id: any = new URLSearchParams(window.location.search).get("job_id")
    const { subscriptionPlans } = useSelector((state: any) => state.subscriptionData)
    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()

        setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex - 1])

        setIsSubmitButton(stepper.current.currentStepIndex === (stepper.current.totalStepsNumber - 1))
    }

    const submitStep = async (values: ICreateJob | any, { setValues }: FormikValues) => {
        setLoading(true)
        if (!stepper.current) {
            return setLoading(false)
        }
        if (stepper.current.currentStepIndex === 5) {
            if (isEdit) {
                let body = {
                    name: values.jobName,
                    schedule: values.cronString,
                    timeZone: values.timeZone.value
                }
                updateJob(job_id, body)
                    .then(() => {
                        navigate("/job/list")
                        setLoading(false)
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    });
            } else {
                let body = {
                    name: values.jobName,
                    sourcePlatformId: values.chooseSource.id,
                    targetPlatformId: values.chooseDestination.id,
                    schedule: values.cronString,
                    timeZone: values.timeZone.value,
                    subscriptionId: values.subscriptionId,
                    stripeProductId: values.stripeProductId,
                    stripePriceId: values.stripePriceId,
                    paymentMethodId: values.paymentMethodId,
                }
                createJob(body)
                    .then((res: any) => {
                        if (!res?.data?.subscriptionDetails?.clientSecret) {
                            successToast("Backup created successfully")
                            return navigate("/job/view?tabStep=Overview&job_id=" + res?.data?.jobDetails?.id)
                        }
                        dispatch(changeJobIdForPaymentSuccess(res?.data?.jobDetails?.id))
                        setValues({ ...values, clientSecret: res?.data?.subscriptionDetails?.clientSecret })
                        if (stepper.current) {
                            stepper.current.goNext()
                        }
                        setLoading(false)
                    })
                    .catch((error: any) => {
                        errorToast(error?.message)
                        setLoading(false)
                    });
            }
        } else if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            stepper.current.goNext()
            setLoading(false)
        }

        setIsSubmitButton(stepper.current.currentStepIndex === (stepper.current.totalStepsNumber - 1))

        setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex - 1])
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    useEffect(() => {
        if (isEdit) {
            getJobs({ id: job_id })
                .then((res: any) => {
                    const data: any = res?.data[0];
                    let timeZone: any = {};
                    if (data?.status === jobStatus.paymentRequired) {
                        navigate("/job/view?tabStep=Overview&job_id=" + job_id)
                        errorToast("Payment is required for edit backup")
                    }
                    getLabeledOptions().forEach((item: any) => {
                        if (item.label === data?.timeZone.split("/")[0]) {
                            timeZone = item.options.filter((item1: any) => item1.value === data?.timeZone || item1?.similarTZ?.includes(data?.timeZone))[0]
                        }
                    })

                    let initSets: any = {
                        jobName: data.name,
                        platformSelect: data?.sourcePlatform?.platform,
                        chooseSource: data?.sourcePlatform,
                        destinationSelect: data?.targetPlatform?.platform,
                        chooseDestination: data?.targetPlatform,
                        cronType: "custom",
                        biWeeklyDaySelected: biWeekDaysArray[0].label,
                        alternate: ["1", "3"],
                        cronString: data?.schedule,
                        cronStringNotAllowed: false,
                        timeZone,
                        maxRuns: 0,
                        clientSecret: ""
                    }


                    let _scheduleOption = data.schedule?.split(' ')
                    let isAlternateSchedule = _scheduleOption[_scheduleOption.length - 1].includes('#')
                    if (isAlternateSchedule) {
                        initSets.cronType = "bi-weekly";

                        let tempCron = new Cron(data.schedule, { paused: true })
                        let weekDay = DayJS(tempCron.nextRun()).get('day')
                        tempCron.stop()
                        let [firstDay, secondDay] = _scheduleOption[_scheduleOption.length - 1].split(',')

                        if (firstDay) firstDay = firstDay.split('#')[1]
                        if (secondDay) secondDay = secondDay.split('#')[1]

                        console.log({ firstDay, secondDay });
                        initSets.alternate = [firstDay, secondDay]

                        const daySelected = biWeekDaysArray.find((item: any) => item.value === weekDay)?.label

                        initSets.biWeeklyDaySelected = daySelected;
                    }
                    setInitValues(initSets)
                    setIsSuccess(true)
                })
                .catch((res: any) => {
                    errorToast(res?.message)
                    navigate("/job/list")
                    // setInitValues(res?.data)
                })
        }
        if (!Object.keys(subscriptionPlans).length) {
            getSubscriptionPlans()
                .then((res: any) => {
                    dispatch(changeSubscriptionPlans(res?.data))
                })
                .catch((err: any) => {
                    errorToast(err?.message)
                })
        }
    }, [])

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div
                        ref={stepperRef}
                        className='stepper stepper-links d-flex flex-column'
                        id='kt_create_account_stepper'
                    >
                        <div className='stepper-nav mb-5'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Description</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Source</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Destination</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Schedule</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Preview</h3>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Subscription</h3>
                            </div>
                        </div>

                        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep} enableReinitialize={true}>
                            {({ values, setValues, errors, isValidating }) => {
                                // { isValidating && Object.keys(errors).length > 0 && (window.scrollTo(0, 0)) }
                                if (isEdit && !isSuccess) {
                                    return (<div className="text-center"><TableLoader /></div>)
                                }
                                return <Form className='mx-auto mw-800px w-100 py-8' id='kt_create_account_form'>
                                    <div className='current' data-kt-stepper-element='content'>
                                        <JobAddStep1 />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <JobAddStep2 values={values} setValues={setValues} errors={errors} isEdit={isEdit} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <JobAddStep3 values={values} setValues={setValues} errors={errors} isEdit={isEdit} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <JobAddStep4 values={values} setValues={setValues} errors={errors} isEdit={isEdit} currentSchema={currentSchema} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <JobAddStep5Preview values={values} setValues={setValues} stepIndex={stepper?.current?.currentStepIndex} setLoading={setLoading} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <JobAddStep6Payment values={values} setValues={setValues} />
                                    </div>

                                    <div className='d-flex flex-stack pt-15'>
                                        {/* @ts-ignore */}
                                        <div className={`${stepper.current && stepper.current.currentStepIndex === stepper.current.totalStepsNumber ? "d-none" : "d-block"} mr-2`}>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                Back
                                            </button>
                                        </div>
                                        {/* @ts-ignore */}
                                        <div className={`${stepper.current && stepper.current.currentStepIndex === stepper.current.totalStepsNumber ? "d-none" : "d-block"}`}>
                                            <button type='submit' className='btn btn-lg btn-primary me-3' disabled={loading} >
                                                <span className='indicator-label'>
                                                    {isSubmitButton ? loading ? <LoaderSpinner text="please wait..." /> : isEdit ? 'Update' : 'Submit' : "Continue"}
                                                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
