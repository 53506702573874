import * as Yup from 'yup'
import timeZones from './timeZones'
import { DayJS } from '../helpers/Utils'

// Platforms constants
const platformNames = {
    zoho: 'zoho',
    sftp: 'sftp',
    awsS3: 'AWS S3',
    zohoWorkDrive: 'Zoho WorkDrive'
}

const platformsConstant: any = {
    "zoho": {
        name: 'zoho',
        displayName: "Zoho CRM",
        icon: "zoho-logo.svg",
        supportedDestinationPlatforms: ['sftp', "AWS S3", "Zoho WorkDrive"],
        isSource: true
    },
    "sftp": {
        name: 'sftp',
        displayName: "SFTP Server",
        icon: "sftp-server.svg",
        isSource: false
    },
    "AWS S3": {
        name: 'AWS S3',
        displayName: "AWS S3",
        icon: "aws-server.svg",
        isSource: false
    },
    "Zoho WorkDrive": {
        name: 'Zoho WorkDrive',
        displayName: "Zoho WorkDrive",
        icon: "zoho-work-drive-logo.png",
        isSource: false
    },
}

const biWeekDaysArray = [
    {
        label: 'Sunday',
        value: 0
    },
    {
        label: 'Monday',
        value: 1
    },
    {
        label: 'Tuesday',
        value: 2
    },
    {
        label: 'Wednesday',
        value: 3
    },
    {
        label: 'Thursday',
        value: 4
    },
    {
        label: 'Friday',
        value: 5
    },
    {
        label: 'Saturday',
        value: 6
    },
]

interface ISftpAdd {
    host: string | undefined
    username: string | undefined
    password: string | undefined
    port: number | undefined
    path: string | undefined
    platformName: string | undefined
    passphrase: string | undefined
    privateKeyFile: string | undefined
}
const sftpAddSchema = Yup.object().shape({
    platformName: Yup.string().required('Platform Name is required'),
    host: Yup.string().required('Host is required'),
    username: Yup.string(),
    password: Yup.string(),
    port: Yup.number().typeError("Invalid Port").required('Invalid Port'),
    path: Yup.string().required('Path is required'),
    passphrase: Yup.string(),
    privateKeyFile: Yup.string(),
})

interface IAWSAdd {
    platformName: string | undefined
    access_key_id: string | undefined
    secret_key_id: string | undefined
    region: string | undefined
    bucket_name: string | undefined
    path: string | undefined
    endpoint: string | undefined
}
const awsAddSchema = Yup.object().shape({
    platformName: Yup.string().required('Platform Name is required'),
    access_key_id: Yup.string().required('Access key ID is required'),
    secret_key_id: Yup.string().required('Secret key ID is required'),
    region: Yup.string().required('Region is required'),
    bucket_name: Yup.string().required('Bucket Name is required'),
    path: Yup.string().required('Path is required'),
    endpoint: Yup.string()
})

// JOB constants
interface ICreateJob {
    jobName: string
    platformSelect: string
    chooseSource: object
    destinationSelect: string
    chooseDestination: object
    cronType: string
    biWeeklyDaySelected?: string
    alternate?: [string | number, string | number]
    cronString: string
    cronStringNotAllowed: boolean
    timeZone: object
    maxRuns: number
    clientSecret: string
    subscriptionId?: string
    stripeProductId?: string
    stripePriceId?: string
    paymentMethodId?: string
}
const createJobSchemas = [
    Yup.object({
        jobName: Yup.string().required().label('Backup Name'),
    }),
    Yup.object({
        platformSelect: Yup.string().required().label('platform Name'),
        chooseSource: Yup.object({
            platformName: Yup.string().required().label('Source platform'),
            id: Yup.string().required(),
        }).label("Source platform"),
    }),
    Yup.object({
        destinationSelect: Yup.string().required().label('destination Name'),
        chooseDestination: Yup.object({
            platformName: Yup.string().required().label('Storage platform'),
            id: Yup.string().required(),
        }),
    }),
    Yup.object({
        cronType: Yup.string().required().default("custom").label('biweekly'),
        biWeeklyDaySelected: Yup.string().default(biWeekDaysArray[0].label).optional(),
        alternate: Yup.array().optional(),
        cronString: Yup.string().required().label('Backup Name'),
        timeZone: Yup.object({
            value: Yup.string().required().label('Time zone'),
        }),
        cronStringNotAllowed: Yup.boolean().required().oneOf([false], 'Backup schedule must have 24-hour difference between each backup').label('Backup schedule is required'),
        maxRuns: Yup.number().max(30, 'Maximum 30 runs is allowed').required()
    }),
]

const getLabeledOptions = () => {
    const labelArray: any = []
    timeZones?.map((v: any) => {
        const Options = v.newRow.map((w: any) => { return { value: w.tz, label: `${w.tz} (${w.country})`, similarTZ: w.similarTZ }; })
        labelArray.push({ label: v.continent, options: Options })
    })
    return labelArray;
}

let userTimeZone = {}
getLabeledOptions().every((item: any) => {
    userTimeZone = item.options.find((item1: any) => item1.value === DayJS.tz.guess() || item1?.similarTZ?.includes(DayJS.tz.guess()))
    if (!userTimeZone) return true
})

const jobInits: ICreateJob = {
    jobName: '',
    platformSelect: platformsConstant.zoho.name,
    chooseSource: {},
    destinationSelect: platformsConstant?.[platformsConstant.zoho.supportedDestinationPlatforms[0]].name,
    chooseDestination: {},
    cronType: "custom",
    cronString: "0 2 * * *",
    biWeeklyDaySelected: biWeekDaysArray[0].label,
    alternate: ["1", "3"],
    cronStringNotAllowed: false,
    timeZone: userTimeZone || {},
    maxRuns: 0,
    clientSecret: "",
    subscriptionId: undefined,
    stripeProductId: undefined,
    stripePriceId: undefined,
    paymentMethodId: undefined
}

// react-select functions

const pageLimitOptions = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
];

const historyStatusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Success", value: "success" },
    { label: "Failed", value: "failed" },
    { label: "Running", value: "running" },
]

const jobStatus = {
    active: 'active',
    inactive: 'inactive',
    pause: 'pause',
    paymentRequired: 'payment_required'
}

const subscriptionStatus = {
    active: 'active',
    paused: 'paused',
    cancelled: 'cancelled',
    due: 'due',
    incomplete: 'incomplete',
    incomplete_expired: 'incomplete_expired'
}

const jobHistoryStatus: any = {
    pending: 'pending',
    success: 'success',
    failed: 'failed',
    running: 'running'
}
const jobHistoryStagesType: any = {
    info: 'info',
    error: 'error',
    warning: 'warning',
    success: 'success',
}

const jobHistoryStages = {
    initializing: "Initializing",
    healthCheck: "Health Check",
    sourceHealthCheck: "Source Platform Health Check",
    targetHealthCheck: "Target Platform Health Check",
    trigger: "trigger"
}

const gatewayType = {
    stripe: 'stripe',
    manual: 'manual'
}

const transactionStatus = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
    refunded: 'refunded',
    paymentRequire: 'payment_require'
}

const transactionType = {
    compensation: 'compensation',
    gift: 'gift',
    purchase: 'purchase',
}

const transactionItemType = {
    jobCredit: 'Job Credit'
}

const cardTypeConstants: any = {
    visa: "visa",
    mastercard: "mastercard",
    amex: "american-express",
    bitcoin: "bitcoin 1"
}

const currencySymbols: { [key: string]: string } = {
    USD: '$',
    EUR: '€',
    AUD: '$'
};

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

const referenceCode: any = {
    basic: 'BACKUP_BASIC',
    standard: 'BACKUP_STANDARD',
    premium: 'BACKUP_PREMIUM',
    ultimate: 'BACKUP_ULTIMATE',
}

export { platformNames, platformsConstant, sftpAddSchema, awsAddSchema, createJobSchemas, jobInits, getLabeledOptions, pageLimitOptions, jobHistoryStatus, jobHistoryStagesType, historyStatusOptions, jobStatus, transactionStatus, subscriptionStatus, cardTypeConstants, stripePublishableKey, currencySymbols, biWeekDaysArray, referenceCode }
export type { ICreateJob, ISftpAdd, IAWSAdd }
