import { ApiGet, ApiPatch, ApiPost } from "../../../_metronic/helpers/Utils"

export const getPlatforms = (queryString?: object) => {
    return ApiGet("/platform/get-platforms", queryString)
}

export const getPlatformsHealthCount = () => {
    return ApiGet("/platform/platforms-health-count")
}

// Zoho APIs and Functions
export const zohoAuthCallback = (body: any) => {
    return ApiPost("/platform/zoho/oauth-callback", body)
}

export const zohoWorkDriveAuthCallback = (body: any) => {
    return ApiPost("/platform/zoho-WorkDrive/oauth-callback", body)
}

export const updateZohoPlatform = ({ id, platformName }: { id: string | undefined; platformName: string }) => {
    return ApiPost("/platform/zoho/update-platform/" + id, { platformName })
}

export const zohoHealthCheck = (id: any) => {
    return ApiGet("/platform/zoho/check-health/" + id)
}

export const getZohoAccountInfo = (id: any) => {
    return ApiGet("/platform/zoho/account-info/" + id)
}

export const getZohoBackupURLs = (id: any) => {
    return ApiGet("/platform/zoho/get-backup-urls/" + id)
}

export const updateSftpPlatform = ({ id, platformName }: { id: string | undefined; platformName: string }) => {
    return ApiPatch("/platform/sftp/update/" + id, { platformName })
}

export const updateAwsS3Platform = ({ id, platformName }: { id: string | undefined; platformName: string }) => {
    return ApiPatch("/platform/aws-s3/update/" + id, { platformName })
}

export const UpdateZohoWorkDrivePlatform = ({ id, platformName }: { id: string | undefined; platformName: string }) => {
    return ApiPost("/platform/zoho-WorkDrive/update-platform/" + id, { platformName })
}

export const getZohoWorkDriveAccountInfo = (id: any) => {
    return ApiGet("/platform/zoho-WorkDrive/account-info/" + id)
}

export const zohoWorkDriveHealthCheck = (id: any) => {
    return ApiGet("/platform/zoho-WorkDrive/check-health/" + id, { refreshAccessTokenIfInvalid: true })
}

export const getZohoWorkDriveDirectory = ({ platformId, fetchNextToken }: { platformId: string; fetchNextToken?: string | undefined; }) => {
    return ApiPost("/platform/zoho-WorkDrive/fetch-directories", { platformId, fetchNextToken })
}

export const updateZohoWorkDriveDirectory = (platformId: string, body: any) => {
    return ApiPost("/platform/zoho-WorkDrive/update-platform/" + platformId, body)
}