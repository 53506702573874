import { useDispatch, useSelector } from 'react-redux'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, Search } from '../../../partials'
import { useEffect } from 'react'
import { ApiGet, errorToast } from '../../../helpers/Utils'
import { AppDispatch } from '../../../../app/redux/store'
import { changeProfileData } from '../../../../app/redux/Slices/ProfileSlice'
import LoaderSpinner from '../../../helpers/components/LoaderSpinner'
import { getProfile } from '../../../../app/redux/Functions/ProfileFunctions'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { profileData } = useSelector((state: any) => state.profileData)

  useEffect(() => {
    if (!Object.keys(profileData).length) {
      getProfile()
        .then((res: any) => {
          dispatch(changeProfileData(res?.data))
        })
        .catch((error: any) => {
          errorToast(error.message)
        })
    }
  }, [])

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img src={toAbsoluteUrl('/media/avatars/blank_profile.png')} alt='1' />
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              {/*begin::Username*/}
              <a className='text-white fs-6 fw-bold'>
                {profileData?.firstName || <LoaderSpinner />} {profileData?.lastName}
              </a>
              {/*end::Username*/}

              {/*begin::Description*/}
              <span className='text-gray-600 text-capitalize fw-bold d-block fs-8 mb-1'>{profileData?.role?.name || ""}</span>
              {/*end::Description*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className='aside-search py-5'>
        <Search />
      </div> */}
      {/*end::Aside search*/}
    </>
  )
}

export { AsideToolbar }
