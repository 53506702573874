/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
//import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      <AsideMenuItem
        to='/platform'
        icon='gift'
        title={"Platforms"}
      />
      <AsideMenuItem
        to='/job'
        icon='switch'
        title={"Backups"}
      />
      <AsideMenuItem
        to='/billing'
        fontIcon='bi-bank'
        title={"Billing"}
      />
      {/* <AsideMenuItem
        to='/pages/wizards/horizontal'
        title='Horizontal'
        hasBullet={true}
      /> */}
      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/pages' title='Pages' icon='gift'>
        <AsideMenuItemWithSub to='/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/accounts' title='Accounts' icon='profile-circle'>
        <AsideMenuItem to='/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/widgets' title='Widgets' icon='element-plus'>
        <AsideMenuItem to='/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' /> */}
    </>
  )
}
